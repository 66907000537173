﻿/// <reference path="../entries/main">

.reveal-panel {
    overflow: hidden;
    height: 0;
    width: 100%;
    transition: ($transition * 1.5) ease-in-out height, ($transition * 1.5) ease-in-out margin;
    position: relative;

    &.opened {
        height: auto !important;
    }

    &[aria-hidden="true"] {
        height: 0 !important;
        margin: 0 !important;
    }

    &-container {
        width: 100%;
        position: relative;
    }
}

[aria-expanded="true"] [data-panel-open-text] {
    display: none;
}

[aria-expanded="false"] [data-panel-close-text] {
    display: none;
}

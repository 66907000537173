﻿/// <reference path="../../entries/main">

.x-btn {
    @extend %btn;
    width: $svg-icon-sm;
    height: $svg-icon-sm;
    min-width: 0;
    position: relative;
    display: inline-block;
    line-height: 1;
    background: transparent;
    padding: 0;
    border: 0;

    &::before,
    &::after {
        content: '';
        display: block;
        width: hypotenuse($svg-icon-sm, $svg-icon-sm) * 0.8;
        border-top: $ui-icon-thickness solid $brand-gold;
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &::before {
        transform: translateX(-50%) rotate(-45deg);
    }

    &::after {
        transform: translateX(-50%) rotate(45deg);
    }
}

/// <reference path="../entries/main">

[data-tooltip] {
    position: relative;
    cursor: help;

    &::before,
    &::after {
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, #{$space});
        transition: 0.3s all;
        transition-delay: 0.1s;
        position: absolute;
        left: 50%;
    }

    &::after {
        content: '';
        border-color: transparent;
        border-top-color: $brand-gold;
        border-style: solid;
        border-width: $space;
        display: block;
        width: 0;
        height: 0;
        bottom: calc(100% - #{$space - 0.0125rem});
    }

    &::before {
        @extend %h6;
        font-size: 1rem;
        line-height: 1;
        content: attr(data-tooltip);
        content: attr(data-tooltip) / "";
        bottom: calc(100% + #{$space});
        background: $brand-gold;
        color: $white;
        padding: $space;
        white-space: nowrap;
        text-align: center;

        @include media(medium) {
            white-space: normal;
            max-width: 9rem;
        }
    }

    &:hover,
    &:focus {
        &::before,
        &::after {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, 0);
        }
    }
}
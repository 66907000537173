﻿/// <reference path="../../../entries/main">
.tn-events-listing-page {
    .tn-prod-list-item__perf-list-item .glyphicon-chevron-right {
        display: none;
    }

    .tn-prod-list-item__perf-property--action {
        @extend %btn-primary;
    }


    .tn-prod-list-item__perf-anchor {
        padding: $space;
        border: none;
        justify-content: space-between;
        & + .tn-prod-list-item__perf-anchor {
            margin-top: $gap-sm;
        }
    }

    .tn-prod-list-item__perf-date, .tn-prod-list-item__perf-time, .tn-prod-list-item__perf-property--title {
        @extend %h6;
    }

    .tn-prod-list-item__perf-property--title {
        @include media-min(tablet) {
            padding-right: $space;
        }
    }

    .tn-prod-list-item__perf-property--datetime {
        @include media-min(tablet) {
            width: auto;
            flex-grow: 0;
        }
    }
}

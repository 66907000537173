﻿/// <reference path="../entries/main">

.hero {
    position: relative;
    min-height: calc(100vh - #{$sticky-nav-height});
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // Using initial value that won't be updated on scroll, but is sized to avoid the bottom menu
    min-height: calc((var(--vh-initial) * 100) - var(--nav-clearance));
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: $charcoal-72;

    @include media($navigation-breakpoint) {
        min-height: $header-image-min-height;
    }


    &-wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        padding: 0 $gap;

        @include media($navigation-breakpoint) {
            padding: 0 $gap-md;
        }
    }

    &-content {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 1 auto;
        z-index: $z-hero-content;

        * + * {
            margin-top: $gap-sm;
        }

        &-title, &-description {
            color: $white;
            max-width: $max-input-width;
            margin-top: 0;
        }

        &-title {
            font-weight: 300;
        }
    }
}

﻿/// <reference path="../tnew">

table th, table td {
    padding: $space / 2;
}

.tn-datepicker td.day[aria-current=date] span {
    background-color: $primary;
    border-color: $primary;
}

.tn-events-calendar__event {
    padding: $gap $space $space;
    overflow: hidden;
}

.tn-events-calendar__event-status {
    margin: $gap-xs -$space -$space;
}

.list-unstyled {
    margin-left: 0;
}

.input-group {
    display: flex;

    .form-control {
        float: none;
        width: auto;
        flex: 1 1 auto;
        display: inline-block;
        max-width: none;
        min-width: 0;
    }
}

.input-group-btn {
    width: auto;
    flex: 0 0 auto;
    display: inline-block;
}

.form-control:focus {
    border-color: $base-text;
}

table, thead {
    background-color: transparent;
}

.tn-syos--cart-details-visible .tn-syos-cart, .tn-syos-mobile-overlay__container, .tn-syos-price-type-selector {
    // make this the height of your sticky nav if you have one
    padding-top: $gap * 2;
}

.modal {
    // make this the height of your sticky nav if you have one
    padding-top: $gap * 2;

    &-dialog {
        pointer-events: auto;

        @include media-min(medium) {
            // Re-overriding TNEW that was overridden by Uskinned
            width: 600px;
        }
    }
}

p, h2, h3, h4, h5 {
    & + p, & + a, & + button, & + div {
        margin-top: $gap-xs;
    }
}

.sr-only:first-child {
    & + p, & + a, & + button, & + div {
        margin-top: 0;
    }
}

.tn-waiting {
    font-weight: normal;
}

// Donation page
.tn-contribution-type-form-container {
    * + h4 {
        margin-top: $gap;
    }
}

.tn-contribution-group-page .tn-heading-sub-text {
    max-width: none
}

.tn-production-season-detail-page-layout__performance-list-container {
    .tn-prod-list-item__perf-property--action {
        padding-right: $space;
    }

    .btn.btn-primary {
        &:before {
            content: none;
            width: 0;
        }

        &:after {
            width: 100%;
            padding: 0;
            margin-left: 0;
            border: none;
        }
    }
}

﻿/// <reference path="../../entries/main">

.btn {
    &-fluid {
        // button that fills available space
        @extend %btn;
        @extend %btn-type;
        width: 100%;
        border-radius: 0;
        text-align: left;
        display: flex;
        align-items: center;

        @include responsive-context($card-padding-h, $x-small-gap-map) {
            padding: $responsive-variable-2 $responsive-variable;
            // extend the padding-right to allow room for icon + $space gap
            padding-right: calc(#{$responsive-variable + $space} + #{$svg-icon-sm});
        }
    }

    &-blur {
        // blur filter applied to content behind it
        backdrop-filter: blur($blur-radius);
        background: transparent;
    }

    &-plus,
    &-dropdown,
    &-arrow {
        // buttons with a right-aligned icon
        @extend %btn-right-aligned-icon;

        &::before,
        &::after {
            @include responsive-context($card-padding-h) {
                right: $responsive-variable;
            }
        }
    }

    &-inline-arrow {
        @extend %btn-right-aligned-icon;
    }

    &-dropdown[aria-expanded="true"] {
        &::before,
        &::after {
            transform: translateY(-50%) rotate(-180deg);
        }
    }
}


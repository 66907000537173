﻿/// <reference path="../../entries/main">

// PROMO CODE
.ace-cal {
    &-promo {
        &-form {
            display: flex;
            align-content: flex-end;
            justify-content: flex-end;
            margin-bottom: $gap;

            @include media(medium) {
                display: flex;
                flex-direction: column;
                margin-top: $gap-sm;
                margin-bottom: 0;

                * + * {
                    margin-top: $space;
                }
            }

            &-control {
                text-align: right;
            }
        }

        &-submit {


            &-remove {
                padding-right: $gap-sm;

                &::after {
                    content: none;
                }
            }
        }

        &-applied {
            white-space: nowrap;
            display: flex;
            align-items: center;
            margin-right: $space;
            @extend %small;
            font-weight: $font-weight-medium;
        }

        &-text {
            text-align: left;
            color: $red;
        }
    }
}


.ace-cal-promo-reveal {
    @extend %btn-plus-minus;
    @include button-reveal($white, $white);

    @include media(medium) {
        margin-top: $gap-sm;
        width: 100%;
        text-align: left;
    }
}
// PROMO CODE - APPLY VIEW
.ace-cal-promo-form-inputs {
    display: flex;
    margin-top: $space;

    @include media(medium) {
        flex-direction: column;
    }

    input {
        max-width: none;

        @include media-min(medium) {
            max-width: $max-input-width / 2;
            margin-right: $gap-sm;
        }
    }
}
// PROMO CODE - REMOVE VIEW
.ace-cal-promo-form-inner {

    .ace-cal-promo-value {
        color: $charcoal;
        padding: $space $gap-sm;
        font-size: 1rem;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

﻿/// <reference path="../entries/main">

%focus {
    @include focus;
}

a,
area,
input,
select,
textarea,
button {
    @extend %focus;
}

﻿/// <reference path="../entries/main">

.pdp-header {

    &-media {
        flex-grow: 1;

        @include responsive-context($standard-padding-map) {
            width: calc(50% - #{$responsive-variable});
        }

        .media-image {
            @extend %cropped-16-by-9;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 50%;

        @include responsive-context($standard-padding-map) {
            padding-right: $responsive-variable;
        }

        @include media(tablet) {
            margin-top: $gap;
            padding-right: 0;

            & * + * {
                margin-top: $space;
            }
        }

        &-pretitle {
            color: $brand-gold;
        }

        &-title, &-description {
            max-width: $max-input-width;

            @include media(tablet) {
                width: 100%;
            }
        }
    }

    &-content, &-media {
        @include media(tablet) {
            width: 100%;
        }
    }
}

// Theme Specific
.theme-charcoal {
    .pdp-header {
        &-content {
            &-title, &-description {
                color: $white;
            }

            &-pretitle {
                color: $accent-yellow;
            }
        }
    }

    &.pdp .breadcrumbs {
        & > * {
            color: $white;
        }
    }
}

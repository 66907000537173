﻿/// <reference path="../entries/main">

.blog-feed {
    &-header {
        margin: $gap 0;
    }


    &-list {
        display: grid;
        grid-gap: $gap;
        grid-template-columns: repeat(auto-fit, minmax(MIN(250px, 100%), .5fr));

        @include media(small) {
            grid-template-columns: 1fr;
        }
    }

    &-item {
        display: flex;
        flex-direction: column;

        &-image {
            @extend %cropped-16-by-9;
        }

        &-title {
            font-weight: $font-weight-normal;
        }

        &-content {
            margin-top: $space;
        }
    }
}

﻿/// <reference path="../../entries/main">

@mixin button-helper($base: $brand-red, $alternate: $brand-red-dark, $contrast: $white) {
    border: 1px solid $base;
    background: $base;
    color: $contrast;

    &::after {
        @include icon-btn-arrow($contrast);
    }

    &:active {
        background: $base;
        color: $contrast;
    }

    &:hover {
        border-color: $alternate;
        background: $alternate;
    }
}

@mixin button-reveal($icon-color: $white, $contrast: $brand-gold-active) {
    color: $icon-color;

    &:focus, &:active {
        color: $icon-color;
    }
    
    &:hover {
        color: $contrast;

        &:after {
            @include icon-minus($contrast);
        }

        &[aria-expanded="true"]::after {

            @include icon-minus($contrast);
        }

        &[aria-expanded="false"]::after {
            @include icon-plus($contrast);
        }
    }

    &[aria-expanded="true"]::after {
        @include icon-minus($icon-color);
    }

    &[aria-expanded="false"]::after {
        @include icon-plus($icon-color);
    }
}

﻿/// <reference path="../../../entries/main">

.form-control {
    @extend %form-element;
    height: auto;
    box-shadow: none;
    transition: none;
    &.tn-amount {
        padding-left: $gap-md;
    }
}

.checkbox, .radio {
    @include checkbox-radio-fixes();
    
}


.form-group {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    .form-control, .input-group {
        width: 100%;
    }
}

input[type=button] {
    width: auto;
    padding: $button-form-padding;
}
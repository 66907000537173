﻿/// <reference path="../../../entries/main">

%hide-arrow {
    &:after {
        display:none;
    }
}

%tnew-primary-btn-reset {
    @extend %hide-arrow;
    padding: $button-form-padding;
}

// Primary Button
.btn, 
.btn-primary, 
.tn-detail-page-header .tn-detail-production-text-toggle-link, 
.tn-gift-certificate-component #apply-gc-submit, 
.tn-gift-certificate-component .tn-apply.tn-payment-gift-certificate-toggle, 
.tn-gift-certificate-component .tn-cancel.tn-payment-gift-certificate-toggle, 
.tn-listing-filter-component .tn-date-filter-reset-button, 
.tn-listing-legend-toggle-link, 
.tn-payment-plan-component .tn-apply.tn-payment-plan-toggle, 
.tn-payment-plan-component .tn-cancel.tn-payment-plan-toggle, 
.tn-payment-plan .tn-apply.tn-payment-plan-toggle, 
.tn-payment-plan .tn-cancel.tn-payment-plan-toggle, 
.tn-subnav-component .tn-promo-box button.tn-subnav-promo-button, 
.tnew_button_as_link {
    border-radius: 0;
    @extend %btn-primary;
    .tn-btn-datepicker__icon-container {
        //color: $text-on-primary;
    }

    span .tn-toggle-btn__icon {
        //color: $text-on-primary;
    }

    &:hover,
    &:focus,
    &:active,
    &:hover:active,
    &:hover:focus,
    &:active:focus {
        background-color: $brand-gold-active;
    }
}

.tn-btn-datepicker__btn-period-prev-next, .tn-events-calendar__event {
    @extend %tnew-primary-btn-reset;
}

// Secondary Button
.tn-additional-events__continue-shopping-link,
.tn-ticketing-mode-change__anchor,
.tn-cart-buttons__secondary-action,
.btn-default,
.btnStyle {
    @extend %btn-primary-alt;

    &.tn-btn-datepicker__btn {
        @extend %tnew-primary-btn-reset;
    }

    .tn-btn-datepicker__icon-container {
        color: $brand-gold;
    }

    span .tn-toggle-btn__icon {
    }

    &:hover,
    &:focus,
    &:active,
    &:hover:active,
    &:hover:focus,
    &:active:focus {
        color: $white;

        .tn-btn-datepicker__icon-container {
            color: $white;
        }
    }

    &[disabled] {
        &:hover {
            color: $disabled;
        }
    }
}

.btn.month, .btn.year {
    background-color: transparent;
    border: 0;
    padding: 0;
}

.tn-event-listing-mode-tab-nav__list-item {
    color: $primary;
}

.glyphicon {
    font-family: Glyphicons Halflings;
}

.tn-prod-list-item__perf-anchor {
    text-decoration: none;
}

button[disabled], html input[disabled] {
    cursor: not-allowed;
}

.tn-toggle-btn {
    padding-right: 3.25rem;

    span.tn-toggle-btn__icon {
        border: 0;
    }
}

// TYPOGRAPHY
.tn-event-listing-view__results-heading {
    color: $base-text;
}

.tn-prod-season-header__title {
    @extend %h2;
}

.tn-flex-seating-notes__btn-edit {
    white-space: initial;
}

.tn-toggle-btn {
    @extend %tnew-primary-btn-reset;

    span.tn-toggle-btn__icon {
        color: $brand-gold-dark;
    }

    &:hover, &:active, &:focus {
        span.tn-toggle-btn__icon {
            color: $white;
        }
    }
}

.tn-heading-sub-text, .tn-unknown-error-component {
    @extend %body;
    max-width: $max-line-length;
}

.tn-subnav-component .tn-login-link.tn-logged-in .tn-logged-in-email, .tn-subnav-component .tn-text, .tn-subnav-component .tn-cart-link .tn-timer {
    @include media(large) {
        // overrides for TNEW's values
        font-size: 1em;
        line-height: 1.3;
    }
}

[class*="__date-range"], [class*="--date-time"] {
    @extend %h6;
    color: $base-text;
}

[class*="__location"], [class*="--location"] {
    @extend %h5;
    color: $base-text;
}


.tn-heading-info, .tn-modal-heading-info {
    background-color: $brand-lightest-gray;
}
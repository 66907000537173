﻿/// <reference path="../../entries/main">

.visually-hidden {
    @include visually-hidden;
}

.card-v {
    @include responsive-context($gutter-map-v) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}

.sixteen-by-nine {
    @extend %sixteen-by-nine;
}

.flex-between {
    @extend %flex-between;
}

.flex-between-desktop {
    @include media-min(small) {
        @include flex-between;
        align-items:center;
    }
}

.flex-center {
    @extend %flex-center;
}
.column-flex-between {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-fiftyfifty {
    @extend %flex-fiftyfifty;

    &-narrow {
        @extend %flex-fiftyfifty;

        @include media-min(small) {
            & > * {
                width: calc(50% - #{$space / 2});

                &:nth-child(odd) {
                    margin-right: $space;
                }
            }
        }

        @include media(small) {
            display: block;

            & > * {
                width: 100%;

                &:nth-child(odd) {
                    margin-right: 0;
                }

                &:first-child ~ * {
                    margin-top: $space;
                }
            }
        }
    }
}

// Show/hide classes from Flex Subs
.#{$prefix}show-for-tablet {
    display: none;

    @media (max-width: $medium-media-query-size) {
        display: block;
    }
}

.#{$prefix}hide-for-tablet {
    display: block;

    @media (max-width: $medium-media-query-size) {
        display: none;
    }
}

.#{$prefix}show-for-small {
    display: none;

    @media (max-width: $medium-media-query-size) {
        display: block;
    }
}

.#{$prefix}hide-for-small {
    display: block;

    @media (max-width: $medium-media-query-size) {
        display: none;
    }
}

﻿/// <reference path="../entries/main">

// ------------- OVERRIDE GOOGLE STYLES ------------- //
.gsc {
    .gsc-wrapper {
        padding-top: $gap;
    }

    .gsc-url-top {
        margin-top: -$space/2;
    }

    .gsc-thumbnail-inside .gs-title a {
        display: inline-block;
        font-family: $heading-font;
        @extend %nav-label-primary;
        text-decoration: underline;
        text-decoration-color: $brand-gold;
        text-transform: none;

        &:hover {
            text-decoration: none;
        }
    }

    .gsc-input {
        max-width: none;
        @include font-size($large-body-font-sizes);
    }

    .gsc-thumbnail {
        display: none;
    }

    .gsc-webResult.gsc-result {
        margin-bottom: $gap;
    }

    .gsc-search-button {
        background-color: $brand-gold;
        border: none;
        padding: 6px 30px;
    }

    .gsc-search-button-v2, .gsc-search-button-v2:hover, .gsc-search-button-v2:focus, .gsc-search-button-v2:active {
        background-image: none;
        filter: none;
        background-color: transparent;
        cursor: pointer;
    }

    .gs-webResult.gs-result a.gs-title:link, .gs-webResult.gs-result a.gs-title:link b, .gs-imageResult a.gs-title:link, .gs-imageResult, .gs-result .gs-title, .gs-result .gs-title * {
        color: $charcoal !important;
        text-decoration-color: $brand-gold;
        font-weight: $font-weight-bold;
        @extend %h4;

        &:active {
            color: $brand-gold;
        }
    }

    .gsc-results .gsc-cursor-box .gsc-cursor-page {
        color: $charcoal;
        text-decoration: underline;
        text-decoration-color: $brand-gold ;

        &:hover, &:focus {
            text-decoration: none !important;
        }

        &.gsc-cursor-current-page {
            font-weight: normal;
            text-decoration: none;
        }
    }

    .gs-webResult div.gs-visibleUrl, .gs-imageResult div.gs-visibleUrl {
        color: $brand-gold;
        font-family: $body-font;
        @include font-size($small-body-font-sizes);

    }

    .gs-bidi-start-align.gs-snippet, .gsc-result-info, .gsc-orderby-label {
        font-family: $body-font !important;
        @include font-size($body-font-sizes);
    }

    .gs-spelling.gs-result a {
        color: $charcoal;
    }

    .gcsc-find-more-on-google {
        color: $charcoal;

        svg {
            fill: $charcoal;
        }
    }

    .gsc-cursor-box.gs-bidi-start-align {
        display: flex;
        justify-content: center;
        font-size: var(--responsive-tiny);
    }

    .gs-no-results-result .gs-snippet {
        background: none;
        font-size: var(--responsive-large);
        text-align: center;
        border: none;
        color: $charcoal;
    }

    .gsst_a .gscb_a {
        color: $brand-gold;
    }
}

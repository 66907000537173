﻿/// <reference path="../../../entries/main">

$mobile-icon-size: 1.25rem;

.nav-mobile {

    @include media-min($navigation-breakpoint) {
        @include hide();
    }


    &-header {
        display: flex;
        width: 100%;
        align-items: center;
        padding: $space 0;

        @include responsive-context($component-gutter-map) {
            padding-left: $responsive-variable;
            padding-right: $responsive-variable;
        }


        &-item {
            margin-right: $nav-item-spacing;

            &:last-child {
                margin-right: 0;
            }

            svg {
                fill: $brand-gold;
                height: $mobile-icon-size;
                width: $mobile-icon-size;
            }
        }

        &-logo {
            flex: 1 1 auto;
            text-align: center;
            margin-left: -$nav-item-spacing;
            margin-right: 0;

            img {
                max-width: pxToRem(50px);
            }
        }
    }


    &-main {
        padding: $gap $gap-md;
        background: $accent-gray;

        .nav-links-item {
            margin-right: 0;
            margin-bottom: $nav-item-spacing;

            @include media-min($navigation-breakpoint) {
                text-align: center;
            }

            &-cta {
                @include media(small) {
                    width: 100%;
                }
            }
        }

        &[aria-hidden="true"] {
            @include hide();
        }
    }
}

// Open state

.mnav-menu-is-open {
    height: 100vh;
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    height: calc((var(--vh, 1vh) * 100));
    overflow-y: auto;
    transition: $transition height;
    z-index: $z-navigation;
}

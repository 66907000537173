﻿/// <reference path="../tnew">

@media print {
    a[href]::after {
        content: none;
    }

    .tn-subnav-component {
        display: none;
    }

    .tn-order-address-information-component, .tn-receipt-component {
        margin-top: $gap-sm;
    }

    .tn-additional-information {
        column-count: 2;
    }

    // These are the Uskinned header/footer and body padding being overridden, replace with your own
    #site {
        & > header,
        & > footer {
            display: none;
        }
    }

    body {
        padding-top: 0 !important;
    }
}

﻿/// <reference path="./__index">

$typography-breakpoints: (
    default,
    medium,
    small
);

/// parameters: size, line-height, letter-spacing (optional)
$h1-font-sizes: ( //h1
    name: h1-text,
    default : (4.5rem, $heading-line-height, -0.018em),
    medium : (2.5rem, $heading-line-height, -0.009em)
);

$h2-font-sizes: ( //h2
    name: h2-text,
    default : (3rem, 1.2, -0.018em),
    medium : (2rem, 1.2, 0em)

);

$h3-font-sizes: ( //h3
name: h3-text, 
    default : (2rem, 1.3, 0em),
    medium : (1.5rem, 1,4, 0em) );

$h4-font-sizes: ( //h4
    name: h4-text,
    default : (1.5rem, 1.4),
    medium : (1.25rem, 1.4)
);

$h5-font-sizes: ( //h5
    name: h5-text,
    default: (1.25rem, 1.5),
    medium: (1rem, 1.5)
);

$h6-font-sizes: (  //h6
    name: h6-text,
    default : (1rem, 1.5),
    medium : (.875rem, 1.5)
);

$label-font-sizes: (
    name: label-text,
    default: (0.875rem, 1.4, 0.071em)
);

$btn-font-sizes: (
    name: label-text,
    default: (1rem, 1.1, 0.091em),
    medium: (0.875rem, 1.1, 0.109em)
);

$checkbox-label-font-sizes: (
    name: checkbox-label-text,
    default: (.875rem, 1 0.06em)
);

$large-body-font-sizes: (
    name: large-body-text,
    default: (1.5rem, 1.5, 0em),
    medium: (1.25rem, 1.5, 0em)
);

$body-font-sizes: ( //body
    name: body-text,
    default : (1.25rem, 1.5, 0.003em),
    medium: (1rem, 1.5, 0.007em)
);

$small-body-font-sizes: ( //body small
    name: small-body-text,
    default : (1rem, 1.5, 0.007em),
    medium: (0.875rem, 1.5, 0.01em)
);

$nav-label-primary-font-sizes: ( //nav label primary
    name: nav-label-primary,
    default: (1rem, 1.1, 0.091em),
    medium: (0.875rem, 1.1, 0.109em)
);


$_all-font-size-maps: (
    $h1-font-sizes,
    $h2-font-sizes,
    $h3-font-sizes,
    $h4-font-sizes,
    $h5-font-sizes,
    $h6-font-sizes,
    $label-font-sizes,
    $btn-font-sizes,
    $large-body-font-sizes,
    $body-font-sizes,
    $small-body-font-sizes,
    $nav-label-primary-font-sizes
);
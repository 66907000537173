﻿/// <reference path="../entries/main">
@import '_gallery-vendor.scss';

$splide-arrows-width: 6.875rem;
$splide-arrows-width-reduced: 5.875rem;
$splide-arrow-btn-size: $square-button-size;
$splide-arrow-btn-size-reduced: 2.5rem;

.gallery {
    padding: 0;
    max-width: none;

    @include media-min(small) {
        @include max-width-offset($space-offset: $space);
    }

    @include media(small) {
        @include responsive-context($standard-padding-map) {
            padding-left: $responsive-variable;
        }
    }


    .splide__arrows {
        display: flex;
        justify-content: space-between;
        width: $splide-arrows-width;

        @include media(medium) {
            width: $splide-arrows-width-reduced;
        }

        @include media-min(small) {
            position: absolute;
            top: 0;
            @include max-width-offset("right", $space-offset: $space);
        }

        @include media(small) {
            margin-top: $gap-sm;
            width: 100%;
            justify-content: flex-end;
            margin-bottom: $gap-md;

            @include responsive-context($standard-padding-map) {
                padding-right: $responsive-variable;
            }
        }
    }

    .splide__arrow {
        @extend %btn;
        @extend %btn-round;
        height: $splide-arrow-btn-size;
        width: $splide-arrow-btn-size;

        &[disabled], &:hover, &:focus {
            background-color: $charcoal-4;

            svg {
                fill: $white;
            }
        }

        @include media(medium) {
            height: $splide-arrow-btn-size-reduced;
            width: $splide-arrow-btn-size-reduced;
        }

        svg {
            fill: $charcoal;
            height: 24px;
            width: 14.82px;
        }

        & + .splide__arrow {
            margin-left: $space;
        }
    }

    .splide__arrow--prev {
        svg {
            transform: rotate(180deg);
        }
    }

    .splide__video__play {
        height: 3rem;
        width: 3rem;
        background-color: $white;

        &:hover, &:focus {
            background-color: $charcoal-4;

            svg {
                fill: $charcoal;
            }
        }
    }

    .splide__slide img {
        height: 46rem;
        width: auto;

        @include media(large) {
            height: 40rem;
        }

        @include media(medium) {
            height: 34.6875rem;
        }

        @include media(medium) {
            height: 22rem;
        }

        @include media(small) {
            height: 10.46rem;
        }
    }

    .splide__video {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;

        iframe, video {
            @extend %full-cover;
        }
    }

    &-intro {
        margin-bottom: $gap;
        min-height: 3.25rem;

        @include responsive-context($standard-padding-map) {
            padding-right: $responsive-variable + $splide-arrows-width;
        }

        @include media(small) {
            padding-right: 0;
            margin: 0;
        }
    }

    &-container {
        position: relative;

        &-padded {
            padding-top: $splide-arrow-btn-size + $gap;

            @include media(medium) {
                padding-top: $splide-arrow-btn-size-reduced + $gap;
            }

            .splide__arrows {
                position: absolute;
                top: 0;
            }
        }
    }

    &-item {
        &-image {
            picture {
                width: 100%;
            }

            img {
                width: 100%;
            }
        }
    }

    &-container {
        @include media(small) {
            display: flex;
            flex-direction: column;

            & > *:nth-child(1) {
                order: 2;
            }

            & > *:nth-child(2) {
                order: 1;
            }

            & > *:nth-child(3) {
                order: 3;
            }
        }
    }
}

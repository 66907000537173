﻿/// <reference path="../../entries/main">

@function generate-background-icon($color: $black, $type: 'invalid-icon') {
    $_url-color: currentColor;

    @if $color != currentColor {
        @if str-slice(#{$color}, 1, 1) == '#' {
            $_url-color: '%23' + str-slice(#{$color}, 2, -1);
        }

        @else {
            $_url-color: $color;
        }
    }

    @if $type == 'eye-hidden' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 22 22'%3E%3Cpath fill='#{$_url-color}' d='M3.4,2.5L2,3.9l2.7,2.7c-1.6,1.3-2.9,3-3.7,5C2.7,15.9,7,19,12,19c1.5,0,3-0.3,4.3-0.8l3.4,3.4l1.4-1.4L3.4,2.5z M9.5,11.4 C9.5,11.4,9.5,11.4,9.5,11.4l2.6,2.5c0,0-0.1,0-0.1,0C10.6,14,9.5,12.9,9.5,11.4C9.5,11.5,9.5,11.5,9.5,11.4z M12,17 c-3.8,0-7.2-2.1-8.8-5.5C3.9,10.1,4.9,8.9,6.1,8l1.8,1.8c-0.2,0.6-0.4,1.2-0.4,1.8c0,2.5,2,4.5,4.5,4.5c0.6,0,1.2-0.1,1.8-0.4l1,1 C13.9,16.9,13,17,12,17z M20.8,11.5C19.2,8.1,15.8,6,12,6c-0.7,0-1.3,0.1-2,0.2L8.4,4.6C9.5,4.2,10.7,4,12,4c5,0,9.3,3.1,11,7.5 c-0.7,1.8-1.8,3.3-3.2,4.5l-1.4-1.4C19.4,13.8,20.2,12.7,20.8,11.5z M13,9.2l-2.1-2.1C11.3,7.1,11.6,7,12,7c2.5,0,4.5,2,4.5,4.5 c0,0.4-0.1,0.7-0.1,1.1l-2.1-2.1C14,9.9,13.6,9.5,13,9.2z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'eye-shown' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 22 22'%3E%3Cpath fill='#{$_url-color}' d='M23,11.5C21.3,7.1,17,4,12,4S2.7,7.1,1,11.5c0,0,0,0,0,0c0,0,0,0,0,0C2.7,15.9,7,19,12,19S21.3,15.9,23,11.5 C23,11.5,23,11.5,23,11.5C23,11.5,23,11.5,23,11.5z M12,17c-3.8,0-7.2-2.1-8.8-5.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0C4.8,8.1,8.2,6,12,6 s7.2,2.1,8.8,5.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0C19.2,14.9,15.8,17,12,17z M16.5,11.5C16.5,9,14.5,7,12,7s-4.5,2-4.5,4.5c0,0,0,0,0,0 c0,0,0,0,0,0c0,2.5,2,4.5,4.5,4.5S16.5,14,16.5,11.5C16.5,11.5,16.5,11.5,16.5,11.5C16.5,11.5,16.5,11.5,16.5,11.5z M12,14 c-1.4,0-2.5-1.1-2.5-2.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0C9.5,10.1,10.6,9,12,9s2.5,1.1,2.5,2.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0 C14.5,12.9,13.4,14,12,14z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'select-dropdown' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43' height='43' width='43'%3E%3Cpolygon fill='#{$_url-color}' points='26.5,19 21.5,24 16.5,19 '/%3E%3C/svg%3E");
    }

    @elseif $type == 'invalid-icon' {
        @return url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M10 20.5C4.4858 20.5 0 16.0146 0 10.5C0 4.9858 4.4858 0.5 10 0.5C15.5142 0.5 20 4.9858 20 10.5C20 16.0146 15.5142 20.5 10 20.5ZM10 2.5C5.5889 2.5 2 6.0889 2 10.5C2 14.9111 5.5889 18.5 10 18.5C14.4111 18.5 18 14.9111 18 10.5C18 6.0889 14.4111 2.5 10 2.5ZM9 4.5V11.5H11V4.5H9ZM11.25 14.5C11.25 15.1904 10.6904 15.75 10 15.75C9.30964 15.75 8.75 15.1904 8.75 14.5C8.75 13.8096 9.30964 13.25 10 13.25C10.6904 13.25 11.25 13.8096 11.25 14.5Z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'valid-icon' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' height='16' width='16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cpath fill='#{$_url-color}' d='M8,1.3c-3.7,0-6.7,3-6.7,6.7s3,6.7,6.7,6.7c3.7,0,6.7-3,6.7-6.7S11.7,1.3,8,1.3z M11.7,6.5l-4.9,4.9 c-0.1,0.1-0.3,0.1-0.4,0L4.2,9.1C4.1,9,4.1,8.9,4.2,8.8l0.6-0.6c0.1-0.1,0.3-0.1,0.4,0l1.5,1.5l4.2-4.2c0.1-0.1,0.3-0.1,0.4,0 l0.6,0.6C11.8,6.2,11.8,6.4,11.7,6.5z'/%3E%3C/svg%3E");
    }

    @elseif $type == 'arrow-right' {
        @return url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'arrow-left' {
        @return url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M4,12l8,8l1.4-1.4L7.8,13H20v-2H7.8l5.6-5.6L12,4L4,12z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'plus-sign' {
        @return url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M14.25 10.25H9.75V14.75H8.25V10.25H3.75V8.75H8.25V4.25H9.75V8.75H14.25V10.25Z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'minus-sign' {
        @return url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 14 6' xml:space='preserve'%3E%3Cpath fill='#{$_url-color}' d='M14,4H0V2h14V4z'/%3E%3C/svg%3E");
    }

    @elseif $type == 'chevron-down' {
        @return url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M5.5575 6.72131L4.5 7.77881L9 12.2788L13.5 7.77881L12.4425 6.72131L9 10.1563L5.5575 6.72131Z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'chevron-up' {
        @return url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M16.59 15.705L18 14.295L12 8.29498L6 14.295L7.41 15.705L12 11.125L16.59 15.705Z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'chevron-right' {
        @return url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z' /%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'chevron-left' {
        @return url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M30.2,38.8l4.3-4.4L20,19.8L34.5,5.3l-4.3-4.4L15.6,15.5l-4.4,4.3l4.4,4.4L30.2,38.8L30.2,38.8z' /%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'translate' {
        @return url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.35 16.92 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.57 16.93 4.04 15.66 3.08 14ZM6.03 6H3.08C4.04 4.34 5.57 3.07 7.41 2.44C6.81 3.55 6.35 4.75 6.03 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM12.34 12H7.66C7.57 11.34 7.5 10.68 7.5 10C7.5 9.32 7.57 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12H14.36Z' fill='%238F5D00'/%3E%3C/svg%3E");;
    }

    @elseif $type == 'search' {
        @return url("data:image/svg+xml,%3Csvg width='154.8' height='154.8' viewBox='0 0 154.8 154.8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M110.6,97.4h-7l-2.5-2.4c8.7-10.1,13.9-23.2,13.9-37.4C115.1,25.8,89.3,0,57.5,0S0,25.8,0,57.5 s25.8,57.5,57.5,57.5c14.2,0,27.3-5.2,37.4-13.9l2.4,2.5v7l44.3,44.2l13.2-13.2L110.6,97.4z M57.5,97.4c-22,0-39.8-17.8-39.8-39.8 s17.8-39.8,39.8-39.8s39.8,17.8,39.8,39.8S79.6,97.4,57.5,97.4z' /%3E%3C/svg%3E%0A");
    }
}

@mixin icon-eye-hidden($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'eye-hidden');
}

@mixin icon-eye-shown($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'eye-shown');
}

@mixin select-dropdown($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'chevron-down');
}

@mixin icon-chevron-right($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'chevron-right');
}

@mixin icon-chevron-left($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'chevron-left');
}

@mixin icon-invalid($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'invalid-icon');
}

@mixin icon-arrow($_bgcolor, $_direction: 'right') {
    @if $_direction == 'right' {
        background-image: generate-background-icon($_bgcolor, 'arrow-right');
    }

    @else {
        background-image: generate-background-icon($_bgcolor, 'arrow-left');
        transform: rotate(180deg);
    }
}

@mixin icon-btn-arrow($_bgcolor, $_direction: 'right') {
    @if $_direction == 'right' {
        background-image: generate-background-icon($_bgcolor, 'arrow-right');
        background-repeat: no-repeat;
        background-position: center center;
    }

    @else {
        background-image: generate-background-icon($_bgcolor, 'arrow-right');
        transform: rotate(180deg);
        background-repeat: no-repeat;
        background-position: center center;
    }
}

@mixin icon-plus($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'plus-sign');
}

@mixin icon-minus($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'minus-sign');
}

@mixin icon-chevron($_bgcolor, $_direction: 'down') {
    @if $_direction == 'down' {
        background-image: generate-background-icon($_bgcolor, 'chevron-down');
    }

    @else {
        background-image: generate-background-icon($_bgcolor, 'chevron-up');
    }
}

@mixin icon-search($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'search');
}

@mixin icon-translate($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'translate');
}
﻿/// <reference path="../entries/main">
$landing-content-gutter: 3rem;

.landing {
    background: transparent;

    &-content-container {
        position: absolute;
        bottom: 0;
        display: flex;
        background: rgba($charcoal, 0.5);
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    &-content {
        padding: $landing-content-gutter 0;
        display: flex;
        flex-direction: column;
        align-items: center;


        & > * {
            color: $white;
        }

        &-cta {
            margin: $gap 0 $gap-sm;
        }

        @include media(medium) {
            padding: $gap 0;
        }
    }
}


.landing-intro {
    display: flex;

    &-title {
        width: calc((100% / 3) - #{$gap});
        min-width: calc((100% / 3) - #{$gap});
        margin-right: $gap;
    }

    &-content {
        flex: 1 1 auto;
    }

    @include media(tablet) {
        flex-direction: column;

        & * + * {
            margin-top: $gap-sm;
        }

        & > * {
            width: 100%;
        }
    }
}

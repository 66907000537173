﻿/// <reference path="../entries/main">

$subnav-gutter: 3rem;
$subnav-gutter-mobile: 1.5rem;

.subnav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $subnav-gutter;

    @include media(medium) {
        grid-gap: $subnav-gutter-mobile;
        grid-template-columns: repeat(2, 1fr);
    }

    @include media(small) {
        grid-template-columns: repeat(1, 1fr);
    }

    &-component {
        background: $charcoal-4;
        margin-top: 0;
    }

    &-item {
        padding: 0;
        position: relative;
        padding-top: 9 / 16 * 100%;
        overflow: hidden;

        @include media(small) {
            padding-top: 5 / 16 * 100%;

            &:hover .subnav-item-content {
                background: none;
            }
        }

        &:hover {
            .subnav-overlay {
                opacity: $carousel-opacity;
            }

            img {
                transform: scale(1.02);
            }
        }



        img {
            transition: $transition;
            @extend %full-cover;
            object-fit: cover;
        }

        &-content {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 100%;
            background: rgba($charcoal, 0.5);
            backdrop-filter: blur(2px);
            color: $white;
            padding: $gap-md;
            bottom: 0;
            left: 0;
            padding-right: $btn-arrow-size + $space;


            &::after {
                @extend %btn-arrow-psuedo;
                @include icon-btn-arrow($white);
            }

            @include media(small) {
                height: 100%;
                padding: $space $gap-sm;
                padding-right: $btn-arrow-size + $space;
                justify-content: center;
            }
        }

        &-description {
            line-height: 1em;
            margin-top: $space;
        }
    }

    &-overlay {
        transition: $transition;
        background: $brand-red;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        opacity: 0;
    }
}

﻿/// <reference path="../tnew">

$_thumbnail-height-desktop: 190px;
$_thumbnail-height-mobile: 168px;
$_gutter-left-of-syos: 25px;

.tn-prod-season-header__description-text-content {
    @extend %body;
    max-width: $max-line-length;
}

.tn-prod-season-header__description-container {
    background: transparent;
    padding: 0;

    @include media-min(large) {
        margin-left: calc(25% + #{$_gutter-left-of-syos});
        min-height: $_thumbnail-height-desktop;
    }

    @include media-range(medium, large) {
        margin-left: calc(35% + #{$_gutter-left-of-syos});
        min-height: $_thumbnail-height-mobile;
    }
}

.tn-prod-season-header {
    border-color: $secondary;
}

.tn-prod-season-header__title {
    align-self: flex-end;
}

// Here I'm pushing the image down so it's faking a layout with the image on the left
// The image is a set width/height and because it's bootstrap the breakpoints have static heights
.tn-prod-season-header__image-wrapper {
    @include media-min(large) {
        width: 25%;
        margin-bottom: -$_thumbnail-height-desktop;
    }

    @include media-range(medium, large) {
        width: 35%;
        margin-bottom: -$_thumbnail-height-mobile;
    }

    @include media-min(medium) {
        margin-right: $_gutter-left-of-syos;
    }
}

.tn-event-detail__display-time, .tn-event-detail__location {
    margin-top: 0;
}

.tn-event-detail__additional-events-container {
    background: transparent;
    padding: 0;
}

.tn-additional-events__instructions {
    @extend %body;
    margin: 0;
    margin-bottom: $gap-sm;
    background: transparent;
    border: 1px solid $secondary;
}

.tn-additional-events__continue-shopping-link {
    width: 100%;
    text-align: center;
    margin-bottom: $gap-sm;

    & + * > button {
        width: 100%;
        text-align: center;
    }
}

.tn-additional-events__controls-container {
    border-color: $secondary;
}

.tn-ticketing-mode-change__anchor {
    font-style: normal;
}

.tn-ticketing-mode-change__content {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.tn-prod-season-header__date-container {
    @extend %h6;
}

.tn-btn-datepicker__btn {
    display: flex;
    align-items: center;
    height: auto;
}
﻿/// <reference path="../../entries/main">


.ace-cal {
    &-filters {
        display: flex;
        align-items: center;
        width: 100%;

        &-container {
            position: sticky;
            left: 0;
            right: 0;
            top: var(--nav-margin-offset);
            z-index: 6;
            width: 100%;
            padding: $space 0;
            background: $calendar-accent;
        }

        &-list {
            position: relative;

            @include media-min($calendar-breakpoint-size) {
                display: flex;
                align-items: flex-end;
            }

            width: 100%;
        }
    }

    &-filter {
        @include media-min(medium) {
            margin-right: $gap-sm;
            flex: 1 1 auto;
            width: 0;

            &:last-child {
                margin-right: 0;
            }
        }

        @include media($calendar-breakpoint-size) {
            @include responsive-property('margin-top', $calendar-gutter-map);

            select {
                max-width: none;
            }
        }
    }

    &-clear-filters {
        @include media-min($calendar-breakpoint-size) {
            text-align: right;
        }

        @include media($calendar-breakpoint-size) {
            text-align: center;
        }

        button {
            text-decoration: underline;
        }
    }

    &-mobile-actions {
        display: flex;
        align-items: center;
        margin-bottom: $space;

        @include media-min(medium) {
            @include hide;
        }

        &-item {
            margin-right: $gap-sm;
        }

        &-clear {
            flex: 1 1 auto;
            text-align: right;

            @include media(medium) {
                @include hide;
            }
        }

        li {
            &:last-child {
                margin-left: auto;
            }
        }
    }

    &-modal-trigger {
        @include font-size($body-font-sizes);

        @include media(medium) {
            .svg-icon-text {
                width: $svg-icon-sm;
                height: $svg-icon-sm;
            }
        }

        &-label {
            @include media(medium) {
                @include visually-hidden;
            }
        }

        .svg-icon-text {
            bottom: -0.125em;
            margin-left: 0;
        }

        &-label {
            margin-right: $space;
        }
    }
}

﻿/// <reference path="./__index">
// ------------- BREAKPOINTS MAP ------------- //

$breakpoints: (
    x-large: $x-large-media-query-size,
    large : $large-media-query-size,
    medium: $medium-media-query-size,
    tablet: $tablet-media-query-size,
    small : $small-media-query-size,
    x-small : $mobile-media-query-size
);

$gutter-map-h: (  // for use in forms and other in-component content in columns
                // DON'T REMOVE ANY BREAKPOINTS.
                // Should be smaller than $standard-padding-map at all breakpoints to prevent overflow of flex grid content w/ "pocket" margins
        default     : $max-gutter-width,
        large       : 2rem,
        medium      : 2rem,
        small       : 1.5rem,
        x-small     : 1rem
    );

$calendar-padding-map: $gutter-map-h;

$gutter-map-v: ( // vertical rhythm in in-component layouts
                // DON'T REMOVE ANY BREAKPOINTS.
        default     : $max-standard-padding,
        large       : 2rem,
        medium      : 2rem,
        small       : 2rem,
        x-small     : 1rem
    );


$standard-padding-map: ( // for global use on page margins. Extend %standard-padding-h or %standard-padding-v
        default     : $max-standard-padding,
        large       : 4rem,
        medium      : 3rem,
        small       : 1.5rem,
        x-small     : 1rem
    );

// Organizing map for component spacing in grid layouts
$grid-spacing-map: $gutter-map-v;

$block-margin-map: ( // for use between blocks. Extend %standard-margin-v
        default     : 6rem,
        large       : 6rem,
        medium      : 4rem,
        small       : 3rem,
        x-small     : 2rem
    );

$component-top-margin-map: ( // for use between blocks. Extend %standard-margin-v
        default     : $component-top-spacing,
        large       : $component-top-spacing,
        medium      : $component-top-spacing,
        small       : $component-top-spacing,
        x-small     : $component-top-spacing,
    );

$component-bottom-margin-map: ( // for use between blocks. Extend %standard-margin-v
        default     : $component-bottom-spacing,
        large       : $component-bottom-spacing,
        medium      : $component-bottom-spacing,
        small       : $component-bottom-spacing,
        x-small     : $component-bottom-spacing,
    );

$component-gutter-map: ( // for use between blocks. Extend %standard-margin-v
        default     : 0rem,
        large       : 2rem,
        medium      : 2rem,
        small       : 2rem,
        x-small     : 1.5rem,
    );

$card-padding-h: $gutter-map-h;

$card-padding-v: $gutter-map-h;

$medium-vertical-gap-map : ( // Comfortable gap between content and large text below
        default     : 3.5rem,
        medium      : 3rem,
        small       : 1.5rem
    );

$standard-vertical-gap-map : map-merge($gutter-map-v, (default: 2rem)); // Same as vertical gutter, with smaller max

$nav-padding-map: ( // Used as navigation top and bottom padding (so far)
        default     : 1.5rem,
        x-large     : 0.875rem
    );

$small-vertical-gap-map : (
        default     : 1.5rem,
        large       : 0.875rem
    );

$x-small-gap-map : (
        default     : 1.5rem,
        large       : 1.5rem,
        medium      : 1.25rem,
        small       : 1rem,
        x-small     : 1rem
    );

$xx-small-gap-map : (
        default     : 1.25rem,
        medium      : 1rem,
        small       : 0.75rem
    );

$small-gutter-map-h : ( // gap between inline-block items or similar usage (utility nav items)
        default     : 1.625rem,
        medium      : 1.3125rem,
        small       : 1rem
    );

$paragraph-margin-map : (
        default     : 1em
    );

$logo-height-map : (
        default     : 1.75rem,
        medium      : 1.5rem,
        small       : 1.25rem
    );

$content-highlight-map: ( // for use with cards or smaller sub-components. Use mixin responsive-property('margin-right', $gutter-map), etc
null : 2rem, x-large : 2rem, medium : 1.5rem, small : 1.25rem );

$col-gutter-map: ( // for use in anything with columns. Use mixin responsive-property('margin-right', $gutter-map), etc.
null : 4rem, x-large : 3rem, medium : 2rem, small : 1.25rem );


$calendar-gutter-map: ( // for use in anything with columns. Use mixin responsive-property('margin-right', $gutter-map), etc.
// DON'T REMOVE ANY BREAKPOINTS.
null : 2.25rem, large : 1.5rem, medium : 1.5rem, tablet : 1.5rem, small : 1.25rem );
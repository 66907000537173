﻿/// <reference path="../../entries/main">

.spinner-container {
    position: relative;
    display: flex;
    flex-grow: 1;
}

.loading-spinner {
    width: 60px;
    height: 60px;
    border: 8px solid rgba($brand-lightest-gray, 0.8);
    border-top: 8px solid $brand-gold-dark-active;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}


@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
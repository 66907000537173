﻿/// <reference path="../../entries/main">

body {
    display: flex;
    flex-direction: column;
}

header {
    flex: 0 0 auto;
}

footer {
    flex: 0 0 auto;
}

main {
    flex: 1 0 auto;
    padding-top: $sticky-nav-height;

    & > *:first-child:not(.content-page-header):not(.component-no-margin):not(.tnew-wrapper) {
        @include responsive-context($grid-spacing-map) {
            margin-top: $responsive-variable;
        }
    }

    & > .flush-top:first-child {
        margin-top: 0;
    }

    &::after {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba($black, 0.4);
        z-index: $z-modal;
    }

    &.nav-open::after {
        content: '';

        @include media($navigation-breakpoint) {
            content: none;
        }
    }
}

.skip-link {
    position: absolute;
    padding: $space;
    z-index: $z-navigation + 1;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    background: $white;
    border-color: transparent;

    &:focus {
        transform: none;
    }
}

.#{$prefix}page {
    &,
    &-section,
    &-header {
        @extend %standard-padding-h;
        @extend %standard-margin-v;
        @extend %standard-max-width;
    }

    &-max-width {
        @extend %standard-padding-h;
        @extend %standard-max-width;
    }
}

.svg-sprite {
    display: none;
}

﻿/// <reference path="../../entries/main">

.stack-xs {
    @include stack($static-spacing: $sliver);

    &-pad {
        @include stack($static-spacing: $sliver, $inner: true);
    }

    &.stack-border {
        @include stack($static-spacing: $sliver, $border: true );
    }
}

.stack-sm {
    @include stack($static-spacing: $space);

    &-pad {
        @include stack($static-spacing: $space, $inner: true);
    }

    &.stack-border {
        @include stack($static-spacing: $space, $border: true );
    }
}

.stack-sm-mobile {
    @include media(small) {
        @include stack($static-spacing: $space);
    }
}

.stack-md {
    @include stack($static-spacing: $gap-sm);

    &-pad {
        @include stack($static-spacing: $gap-sm, $inner: true);
    }

    &.stack-border {
        @include stack($static-spacing: $gap-sm, $border: true );
    }
}

.stack {
    @include stack($spacing-map: $paragraph-margin-map);

    &-pad {
        @include stack($spacing-map: $paragraph-margin-map, $inner: true);
    }

    &.stack-border {
        @include stack($spacing-map: $paragraph-margin-map, $border: true );
    }
}

.stack-lg {
    @include stack($spacing-map: $standard-vertical-gap-map);

    &-pad {
        @include stack($spacing-map: $standard-vertical-gap-map, $inner: true);
    }

    &.stack-border {
        @include stack($spacing-map: $standard-vertical-gap-map, $border: true );
    }
}

.stack-xl {
    @include stack($spacing-map: $block-margin-map);

    &-pad {
        @include stack($spacing-map: $block-margin-map, $inner: true);
    }

    &.stack-border {
        @include stack($spacing-map: $block-margin-map, $border: true );
    }
}

.space-top {
    @include responsive-context($paragraph-margin-map) {
        margin-top: $responsive-variable;

        &.border-top {
            padding-top: $responsive-variable;
        }
    }

    &-xs {
        margin-top: $sliver;

        &.border-top {
            padding-top: $sliver;
        }
    }

    &-sm {
        margin-top: $space;

        &.border-top {
            padding-top: $space;
        }
    }


    &-md {
        margin-top: $gap-sm;

        &.border-top {
            padding-top: $gap-sm;
        }
    }

    &-lg {
        @include responsive-context($standard-vertical-gap-map) {
            margin-top: $responsive-variable;

            &.border-top {
                padding-top: $responsive-variable;
            }
        }
    }

    &-xl {
        @include responsive-context($block-margin-map) {
            margin-top: $responsive-variable;

            &.border-top {
                padding-top: $responsive-variable;
            }
        }
    }
}

.negative-space-top {
    &-xl {
        @include responsive-context($block-margin-map) {
            margin-top: -$responsive-variable*2;
        }
    }
}

%border-top {
    border-top: 1px solid $brand-gold;
}

%border-bottom {
    border-bottom: 1px solid $brand-gold;
}

.stack-border > * + *,
.border-top {
    @extend %border-top;
}

.no-margins {
    & > * + * {
        margin-top: 0;
    }
}

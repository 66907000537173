﻿/// <reference path="../../entries/main">

.ace-cal {
    &-list {
        &-results-container {
            display: grid;
            padding-top: $gap-sm;
            grid-template-columns: 1fr 3fr;
            grid-column-gap: 3rem;
            grid-row-gap: 3rem;
            margin-top: $gap-lg;

            @include media($calendar-breakpoint-size) {
                grid-template-columns: 1fr;
                margin-top: $gap-lg / 2;
            }
        }

        &-mini-cal {
            @include media($calendar-breakpoint-size) {
                @include hide;
            }
        }


        &-month {
            @include responsive-property('margin-bottom', $standard-padding-map);
        }
    }
}

/// <reference path="../../entries/main">

/// minimum size for form element font-size to prevent iOS resizing is 16px

%form-element {
    @include font-size($body-font-sizes);
    font-family: $heading-font;
    line-height: 1;
    padding: $input-form-padding;
    padding: var(--input-form-padding);
    width: 100%;
    max-width: $max-input-width;
    border: $button-form-border solid transparent;
    border-radius: $border-radius;
    outline-offset: 0;
    outline: none;
    border: 1px solid $brand-lightest-gray;
    background-color: transparent;

    &:focus {
        outline: none;
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: $brand-gold;
    }

    &::placeholder {
    }

    &[aria-invalid=true], &.input-validation-error {
        border-color: $ui-invalid;
        @include icon-invalid($ui-invalid);
        background-repeat: no-repeat;
        background-position: right center;
        background-origin: content-box;
    }
}

input,
select,
textarea,
.select-style {
    @extend %form-element;

    & + label {
        margin-top: $space;
    }
}

select, select.form-control /*For TNEW*/ {
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1.35;
    @include select-dropdown($brand-gray);

    &::-ms-expand {
        display: none;
    }
}

textarea {
    min-height: 100px;
}

select,
.select-style {
    padding-right: 0.5rem + ($button-form-padding-v * 2);
    background-size: 1.5rem;
    background-position: right 0.25em top 0.5em;
    background-repeat: no-repeat;

    option {
        background-color: white;
        color: $brand-cool-gray;
        font-family: $heading-font;
        @include font-size($body-font-sizes);
    }
}

// --- Labels --- //

label {
    @extend %label;

    & + input, & + select, & + textarea {
        margin-top: $space;
    }
}

.label-hint {
    font-style: italic;
    flex: 0 1 auto;
    font-weight: $font-weight-normal;
}

.required {
    @extend %small-top-margin;
    color: $ui-invalid;
    position: relative;
    vertical-align: baseline;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.form-field + .form-field {
    margin-top: $gap-sm;
}


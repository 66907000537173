﻿/// <reference path="../../entries/main">
.load-more {
    &-container {
        text-align: center;
    }

    &-cta {
        &::after {
            @include icon-plus($brand-red);
        }

        &:hover::after {
            @include icon-plus($white);
        }
    }
}

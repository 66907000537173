﻿/// <reference path="../../entries/main">

$breadcrumb-breakpoint: "tablet";

.breadcrumbs {
    text-align: center;
    margin-bottom: $gap-md;

    @include media-min($breadcrumb-breakpoint) {
        text-align: left;
    }

    &-item {
        display: inline-block;
        margin-bottom: $space;

        span {
            color: $charcoal-48;
        }

        &-divider {
            display: inline-block;
            font-size: 1rem;
            margin: 0 $sliver;
        }
    }
}


.theme-charcoal .breadcrumbs-item {
    a {
        color: $white;
    }

    &:not(:last-child)::after {
        color: $white-72;
    }

    span {
        color: $white-48;
    }
}
﻿/// <reference path="./__index">

// UI Element sizes
$ui-icon-thickness:     2px; //for pseudoelement arrows, close buttons, etc
$border-radius:         2px; //buttons and form elements
$border-radius-lg:      6px; //cards, headers, alerts
$ui-icon-size:          1.25rem;
$svg-icon-xs:           0.75em;
$svg-icon-sm:           1.25em;
$svg-icon-lg:           2em;
$button-form-padding-h: 1rem;
$button-form-padding-v: 0.8125rem;
$button-form-padding:   $button-form-padding-v $button-form-padding-h;
$button-form-padding-large: 1.0625 1.25rem;
$button-form-border:    1px;
$hub-nav-link-padding:  1.25rem;
$input-form-padding-h:  .5rem;
$input-form-padding-v:   1rem;
$input-form-padding: $input-form-padding-h $input-form-padding-v;
$common-border: 1px solid $charcoal-16;
// Sizing is equal to height of buttons/form inputs
$square-button-size: calc(#{(1rem * 1) + ($input-form-padding-v * 2)});

$button-width: 8rem;
$radiocheck-size: 1.25rem;
$radiocheck-padding: $gap-sm;

// Calendar structure variables
$filter-height: 6rem !default;
$filter-height-mobile: 5rem !default;
$weekdays-height: 2.75rem !default;
$loader-size: 5rem;
$loader-weight: 2; //multiplier scale, not direct pixel weight

// Nav variables (estimated size, only used to preset nav-clearance)
$sticky-nav-height: 6.76rem;
$sticky-nav-height-mobile: 4.144375rem;
$logo-width: 8.3125rem;
$logo-mobile-width: 4.5rem;

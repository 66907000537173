﻿/// <reference path="../entries/main">
$accordion-icon-size: 1.75rem;
$accordion-border: 1px solid $charcoal-16;

// STRUCTURE
.accordion {

    &-panel {
        overflow: hidden;
        transition: $transition height;

        &[aria-hidden="true"] {
            height: 0;
        }
    }
}


// STYLES

.accordion {

    @include media(medium) {
        margin-right: 0;
        flex-direction: column;

        &-inner {
            margin-top: $gap;
        }
    }

    &-heading {
        margin-bottom: 0;
    }

    &-trigger {
        @extend %un-button;
        @include font-size($h4-font-sizes);
        font-weight: $font-weight-bold;
        display: block;
        width: 100%;
        padding-top: $gap-md;
        display: flex;
        padding-bottom: $gap-md;
        align-items: center;

        @include media(small) {
            padding-top: $gap-sm;
            padding-bottom: $gap-sm;
        }

        &-icon {
            margin-right: $gap-sm;
            width: $gap-md * 2;
            height: $gap-md * 2;

            @include media(tablet) {
                width: $gap;
                height: $gap;
            }
        }
    }

    &-item {
        border-top: $accordion-border;
        transition: $transition;

        &:hover {
            .accordion-trigger {
                color: $brand-gold-active;

                &::after {
                    @include select-dropdown($_bgcolor: $brand-gold-active);
                }

                &[aria-expanded="true"] {
                    &::after {
                        @include select-dropdown($_bgcolor: $brand-gold);
                    }
                }
            }
        }
    }

    &-panel {
        &-content {
            padding-bottom: $gap-md;
        }
    }
}
// ARROW/INDICATOR
.accordion {
    &-trigger {
        position: relative;
        padding-right: $accordion-icon-size * 3;

        &::after {
            content: '';
            width: $accordion-icon-size;
            height: $accordion-icon-size;
            background-size: cover;
            display: block;
            position: absolute;
            right: $gap-sm;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            transition: $transition transform;
            @include select-dropdown($_bgcolor: $brand-gold);
        }

        &[aria-expanded="true"] {
            color: $brand-gold-active;

            &::after {
                @include select-dropdown($_bgcolor: $brand-gold);
                transform: translateY(-50%) rotate(-180deg);
            }
        }
    }
}
// HOVER STYLES
.accordion-item:hover {

    .accordion-trigger {
        color: $brand-gold-active;

        &::after {
            @include select-dropdown($_bgcolor: $brand-gold-active);
        }

        &[aria-expanded="true"] {
            &::after {
                @include select-dropdown($_bgcolor: $brand-gold);
            }
        }
    }
}

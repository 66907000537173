﻿/// <reference path="../../entries/main">

.footer {
    background-color: $charcoal-4;
    overflow: hidden;
    @extend %standard-side-gutters;

    &-inner {
        @extend %standard-max-width;

        @include responsive-context($block-margin-map) {
            margin-top: $responsive-variable;
            margin-bottom: $responsive-variable;
        }
    }

    &-social {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        grid-gap: $gap-sm;
        margin-top: $gap;

        &-container {
            label {
                letter-spacing: normal;
            }
        }

        svg {
            fill: $brand-gold;
            width: 100%;
            max-width: 1.5rem;
            height: 1.5rem;
            margin: 0;

            &:hover {
                fill: $brand-gold;
            }
        }

        &-translate {
            padding-left: 0.5rem + ($button-form-padding-v * 2);
            @include icon-translate($brand-gold);
            background-position: left 0.25em top 0.5em;
            padding-right: $gap-sm;
            -moz-appearance: auto;
            -webkit-appearance: auto;

            option {
                padding-left: 0;
            }
        }
    }

    &-logo {

        &-desktop {
            max-width: pxToRem(80px);
            display: block;

            @include media($navigation-breakpoint) {
                @include hide()
            }
        }

        &-mobile {
            max-width: pxToRem(160px);

            @include media-min($navigation-breakpoint) {
                @include hide()
            }
        }
    }

    &-rich-links {
        a {
            @extend %nav-label-primary;
        }
    }

    .rich-text * + * {
        margin-top: $space;
    }

    &-nav {
        display: grid;
        gap: $gap-sm;
        grid-template-columns: repeat(4, 1fr);

        @include media($navigation-breakpoint) {
            grid-template-columns: 1fr;

            .footer-block {
                margin-bottom: $gap;
            }
        }

        @include media(large) {
            width: 100%;
        }
    }

    &-additional {

        & > * {
            margin-top: $gap;
        }
    }


    &-bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include responsive-context($block-margin-map) {
            margin-top: $responsive-variable / 2;
        }

        @include media($navigation-breakpoint) {
            flex-direction: column-reverse;
            justify-content: flex-start;
        }

        .copyright {
            padding-right: $gap-sm;
            color: $charcoal-48;

            @include media($navigation-breakpoint) {
                margin-top: $gap-md;
            }
        }

        &-links {
            display: flex;

            @include media($navigation-breakpoint) {
                flex-direction: column;
            }

            & > * {
                margin-right: $space;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

// Spacing variables
$gap-lg:                    5rem;
$gap:                       2rem;
$gap-md:                    1.5rem;
$gap-sm:                    1rem;
$space-lg:                  0.75rem;
$space:                     0.5rem;
$sliver:                    0.25rem;
$max-gutter-width:          3rem;
$max-gutter-height:         3rem;
$max-standard-padding:      5rem;
$svg-inline-spacing:        0.5em;

// Layout sizing and sizing for ui elements included in layouts
$max-line-length:           37.5rem;
$max-input-width:           40rem;
$max-width:                 78rem;
$max-content-width:         $max-width - ($max-standard-padding * 2);
$max-width-narrow:          40rem;
$max-width-medium:          60rem;
$max-column-width:          ($max-content-width / 12) - ($max-gutter-width * 11 / 12);
$fixed-width-centered:      42rem;
$max-modal-height:          38rem;
$max-modal-width:           40rem;
$link-caret-size:           1rem;
$btn-arrow-size:            2.5rem;
$header-image-min-height:   25rem;
$header-image-min-height-mobile: 20rem;
$nav-decorator-height:      .15625rem;
$header-min-height:          4.5rem ;
$component-top-spacing:      5.75rem ;
$component-bottom-spacing:   5.75rem ;

// Miscellaneous
$one-third:                 99.993% / 3;
$prefix:                    'ace-'; //prefix for project-specific classes
$transition:                0.3s;
$blur-radius:               4px;
$selected-underline-width:  4px;
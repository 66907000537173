﻿/// <reference path="../entries/main">

// LAYOUT
.sponsors {

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &-item {
            flex: 1 1 auto;
            display: flex;
            align-content: center;
            align-items: center;
            max-width: calc(100% / 4);
            width: calc(100% / 4);
            justify-content: center;

            @include media-min(x-large) {
                max-width: calc(100% / 6);
                width: calc(100% / 6);
            }

            @include media(large) {
                max-width: calc(100% / 4);
                width: calc(100% / 4);
            }

            @include media(medium) {
                max-width: calc(100% / 3);
                width: calc(100% / 3);
            }

            @include media(small) {
                min-width: 100%;
                max-width: none;
                width: 100%;
            }
        }

        &-item-large {
            @include media-min(medium) {
                max-width: calc(100% / 3);
                width: calc(100% / 3);
            }

            @include media(medium) {
                max-width: calc(100% / 2);
                width: calc(100% / 2);
            }

            @include media(small) {
                min-width: 100%;
                max-width: none;
                width: 100%;
            }
        }
    }
}
// STYLING
.sponsors {

    &-heading {
        text-align: center;
    }

    &-list {
        padding: $gap 0 0;
        margin-top: $gap-sm;
    }

    &-list-item {
        flex-direction: column;
        padding-bottom: $gap;

        @include media(small) {
            margin-left: -$gap;
            margin-right: -$gap;
            border-top: 1px solid $charcoal-16;
            padding-top: $gap;

            &:last-child {
                border-bottom: 1px solid $charcoal-16;
            }
        }

        &-container {
            @extend %unlink;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin: 0 $gap;
            text-align: center;
        }

        &-links {
            @extend %unlink;
            text-align: center;
            min-height: $gap * 2;
            padding-top: $gap-sm;

            &.no-margin {
                padding-top: 0;
            }
        }

        .label-bold {
            font-weight: $font-weight-bold;
            color: $brand-gold;

            &.spaced {
                margin-top: $gap-sm;
            }
        }

        &-image {
            display: flex;
            flex-grow: 1;
            align-items: center;
        }
    }

    img {
        max-width: pxToRem(190px);
        padding: 0 $gap-sm;
    }

    &-list-item-large img {
        padding: 0;
        max-width: pxToRem(280px);
    }
}

.tiered-sponsors {
    .sponsors + .sponsors {
        margin-top: $gap;
    }
}

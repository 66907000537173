﻿/// <reference path="../entries/main">

%body {
    @include font-size($body-font-sizes);
    font-family: $body-font, serif;
    font-weight: $font-weight-normal;
    color: $body-color;
}

%small {
    @include font-size($small-body-font-sizes);
    font-weight: $font-weight-normal;
}

%large {
    @include font-size($large-body-font-sizes);
    font-weight: $font-weight-normal;
}

%label {
    @include font-size($label-font-sizes);
    text-transform: uppercase;
    font-family: $heading-font;
    font-weight: 700;
}

%btn-type {
    @include font-size($btn-font-sizes);
    text-transform: uppercase;
    font-family: $heading-font;
    font-weight: 700;
}

%radio-checkbox-label {
    @include font-size($body-font-sizes);
    font-weight: $font-weight-normal;
    font-family: $heading-font;
    text-transform:none;
}

.nav-label-primary, %nav-label-primary {
    @include font-size($nav-label-primary-font-sizes);
    font-weight: $font-weight-bold;
    text-decoration: none;
    text-transform: uppercase;

    &.active {
        border-bottom: 1px solid $brand-gold;
    }
}


%hyperlink {
    transition: $transition;
    text-decoration: underline;
    cursor: pointer;
    @include link-helper();
}

%unlink, .unlink {
    border: none;
    text-decoration: none;
}

%bold {
    font-weight: $font-weight-bold;
}

%medium {
    font-weight: $font-weight-normal;
}

%italic {
    font-style: italic;
}
﻿/// <reference path="../../entries/main">

.password-input {
    position: relative;

    &-toggle {
        display: block;
        position: absolute;
        right: $svg-icon-sm / 2;
        top: 50%;
        transform: translateY(-50%);
        width: $ui-icon-size;
        height: $ui-icon-size;
        background: no-repeat center center;
        cursor: pointer;

        @include icon-eye-shown($form-input-accent);

        &[aria-pressed="true"] {
            @include icon-eye-hidden($form-input-accent);
        }
    }

    input:not([type="checkbox"]):not([type="radio"]) {
        padding-right: $svg-icon-sm * 2;
    }
}

﻿/// <reference path="../../entries/main">

.btn-icon {
    @extend %btn;
    @extend %btn-primary;
    width: $square-button-size;
    height: $square-button-size;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-icon-round {
    @extend %btn;
    @extend %btn-round;
}

.btn-icon,
.btn-icon-round {
    @include font-size($large-body-font-sizes);
}
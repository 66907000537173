﻿/// <reference path="../../entries/main">

.umbraco-forms {

    &-page {
        * + * {
            margin-top: $space;
        }

        .radiobuttonlist {
            margin: 0;
        }

        br:not(.line-break) {
            display: none;
        }

        .form-item + .form-item {
            margin-top: $gap;
        }

       .field-validation-error {
            color: $brand-red;
            @extend %small;
        }

    }

    &-row {
        display: flex;
        justify-content: space-between;
        margin-top: 0;

        @include media(medium) {
            display: block;
        }
    }

    &-container {
        flex: 0 0 auto;
    }

    &-field {
        &.checkbox {
            margin: 0;

            label {
                padding: 0;
                min-height: 0;
            }
        }
    }

    &-checkbox[type="checkbox"]:nth-child(n) {
        position: static;
        margin: 0;
    }

    &-indicator {
        color: $brand-red;
    }


    &-label {
        & + * {
            margin-top: 0;
        }
        & + select {
            margin-top: $space;
        }
    }

    &-navigation {
        margin: $gap 0;
    }

    &-page fieldset {
        margin-top: $space;
    }

    &-field {
        .h6-style {
            text-transform: none !important;
            font-family: $body-font;
            font-weight: $font-weight-bold;
            font-size: 1rem;
        }
    }

    &-field.fileupload {
        label {
            @extend %h6;
        }

        input {
            @extend %small;
        }
    }
}

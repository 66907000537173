﻿/// <reference path="./__index">

// ------------- FONTS ------------- //
// body font is placeholder until finalized
$body-font: 'futura-pt', serif;
$heading-font: 'futura-pt', sans-serif;
$accent-font: 'EB Garamond', serif;

// ------------- FONT WEIGHTS ------------- //
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

// ------------- LINE HEIGHTS ------------- //
$heading-line-height: 1.1; // just for h1
$body-line-height: 1.5; // just for body on desktop

$link-styling: ($body-color, $brand-gold, $brand-gray)
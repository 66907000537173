﻿/// <reference path="../tnew">

@mixin checkbox-radio-fixes() {
    width: 100%;
    margin: 0;

    label {
        padding-left: 0;
        display: block;

        input {
            position: relative;
            width: auto;
            max-width: initial;
            margin: 0;
            margin-right: $space;
        }
    }
}

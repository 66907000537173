﻿/// <reference path="../entries/main">

%h1, %h2, %h3, %h4, %h5, %h6 {
    font-family: $heading-font;
    font-weight: $font-weight-normal;
    color: $body-color;
}

%h4, %h5, %h6 {
    font-weight: $font-weight-bold;
}

%h1 {
    @include font-size($h1-font-sizes);
}

%h2 {
    @include font-size($h2-font-sizes);
}

%h3 {
    @include font-size($h3-font-sizes);
}

%h4 {
    @include font-size($h4-font-sizes);
}

%h5 {
    @include font-size($h5-font-sizes);
}

%h6 {
    @include font-size($h6-font-sizes);
    text-transform: uppercase;
}
/// <reference path="../../entries/main">

label {
    display: block;
}

input[type="checkbox"],
input[type="radio"] {
    & + label {
        display: inline-block;
    }
}
﻿/// <reference path="../../entries/main">


@mixin focused() {
    box-shadow: 0 0 0 2px $focus-color;
    outline-offset: 1px;

}

@mixin focus() {
    outline: 1px solid transparent;
    outline-offset: 3px;
    transition: $transition all;

    &:focus {
        @include focused;
    }
}


﻿/// <reference path="../../../entries/main">

$_syos-actions-height: 4.9375rem;

.tn-syos-screen-button {
    @extend %btn-primary;
    @extend %hide-arrow;
    padding: $button-form-padding;

    h4 {
        color: $white;
    }
}

.tn-syos-screen-button__section-heading {
    @extend %h6;
    padding-bottom: 0;
    border: 0;
}

.tn-syos-screen-container__button-list-container {
    background: transparent;
    border: 0;

    @include media-min(1000px) { // TNEW's arbitrary breakpoint, not mine
        width: 220px;
        padding-right: 0;
    }
}

.tn-syos__layout-container {
    background: transparent;

    @include media-min(medium) {
        border: 1px solid $secondary;
    }
}

.tn-syos-mobile-cart-controls__summary-subtotal {
    @extend %h4;
}

.tn-syos-mobile-cart-controls__summary,
.tn-syos-mobile-cart-controls {
    height: $_syos-actions-height;
}

.tn-syos--viewing-seats .tn-syos-mobile-cart-controls {
    bottom: -$_syos-actions-height;
}

.tn-syos-seat-info__price-type:nth-child(n) {
    display: flex;
}

.tn-syos-seat-info__header {
    table, th, td {
        border: 0;
    }

    table {
        margin-bottom: $space;
    }
}

.tn-syos__btn-add-to-cart {
    @extend %btn-primary;
}

.tn-syos-mobile-cart-controls__item {
    &:first-child {
        border-left-width: 0;
    }

    border-width: 1px 0 0 1px;
    border-color: $secondary;
}
﻿/// <reference path="../../entries/main">

$image-to-content-width-ratio-map: ( // ratio of image to details at each breakpoint
// MUST MATCH calendar-GUTTER-MAP
null: 2, large: 2, medium: 2, tablet: 1, small: 1 );

.ace-cal {
    &-list {
        &-day {
            @include responsive-property('margin-top', $calendar-padding-map);
            @include responsive-property('margin-bottom', $calendar-gutter-map);
            scroll-margin-top: calc(var(--nav-clearance) + 2rem);

            &-date {
                border-bottom: 1px solid $primary-calendar-dark;
            }
        }

        &-event {
            @include responsive-property('margin-top', $calendar-padding-map);
            background: $white;

            &-cta {
                margin-top: $gap-sm;
            }
        }
    }
}


// ITEM STRUCTURE
.ace-cal-list {
    &-event {
        @supports(display: grid) {
            &-content {
                @include responsive-property('grid-gap', $calendar-gutter-map);
            }

            &-details {
                padding: $gap-sm;
            }

            @include media-min(small) {
                &-content {
                    display: grid;
                }

                &-image, &-details {
                    width: auto;
                }

                &-details {
                    padding: $gap;
                    margin-left: $gap-md;
                }
            }




            @each $breakpoint, $value in $image-to-content-width-ratio-map {
                @if $breakpoint == null {
                    &-content {
                        grid-template-columns: repeat(#{$value + 1}, 1fr);
                    }

                    &-details {
                        grid-column: span $value;
                    }
                }
                @else {
                    @include media($breakpoint) {
                        &-content {
                            grid-template-columns: repeat(#{$value + 1}, 1fr);
                        }

                        &-details {
                            grid-column: span $value;
                        }
                    }
                }
            }

            @include media(small) {
                grid-template-columns: 1fr;
            }
        }

        &-venue {
            margin-top: $gap-md;
            color: $charcoal;
        }

        &-presenter {
            color: $brand-gold;
            margin-bottom: $space;
        }
        &-ctas {
            display: flex;

            & > * {
                margin-right: $gap-sm;
            }
        }

        &-inner-content {
            position: absolute;
            display: flex;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            justify-content: center;
            align-items: center;
            z-index: 4;
            flex-direction: column;

            .ace-cal-list-event-time, .ace-cal-list-event-name {
                color: $white;
                text-align: center;
            }
        }
    }

    &-reveal {
        &::after {
            @include select-dropdown($_bgcolor: $brand-gold-dark-active);
        }

        &:hover, &:active, &:focus {

            &::after {
                @include select-dropdown($_bgcolor: $brand-gold-active);
            }
        }

        &[aria-expanded="true"] {
            &::after {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
    }
}

// IMAGE RATIO
.ace-cal {
    &-list-event-image {
        &-inner {
            width: 100%;
            height: 0;
            position: relative;
            overflow: hidden;
            padding-bottom: ratioToPercent(1, 1);
        }

        &-overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background: $charcoal-72;
            top: 0;
            left: 0;
        }

        &-img {
            width: 100%;
            height: auto;
            max-width: none;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);

            @supports (object-fit: cover) {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: var(--focal-point-h) var(--focal-point-v);
                transform: none;
                left: 0;
                top: 0;
            }
        }
    }
}

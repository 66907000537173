﻿/// <reference path="../../entries/main">

.btn,
.btn-primary {
    @extend %btn-primary;
}

.btn-primary-alt {
    @extend %btn-primary-alt;
}

.btn-secondary {
    @extend %btn-secondary;
}

.btn-secondary-alt {
    @extend %btn-secondary-alt;
}

.btn-link-primary {
    @extend %btn-link-primary;
}

.btn-link-secondary {
    @extend %btn-link-secondary;
}

.btn-link-white {
    @extend %btn-link-white;
}


.un-button {
    @extend %un-button;
}

.btn-full-width {
    width: 100%;
    max-width: 100%;
}

.btn-wide {
    @include media(small) {
        width: 100%;
        max-width: 100%;

        & + & {
            margin-top: $space;
        }
    }
}



// only used on package listing app
.#{$prefix}btn-link {
    @extend %hyperlink;
    font-weight: $font-weight-normal;
    text-decoration: underline;
}

// used only on logout button
.#{$prefix}link {
    @extend %hyperlink;
    font-weight: $font-weight-bold;
    text-decoration: none;
}

// only used in add-ons and package-listing apps
.#{$prefix}ui-btn {
    text-align: center;
    text-decoration: none;
    position: relative;
    font-weight: bold;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: none;
    transition: $transition all;
    color: $brand-gold;
    padding: $space $gap-sm;
}

﻿/// <reference path="../../../entries/main">

$navigation-breakpoint: "medium";
$nav-item-spacing: 1.25rem;

.nav {
    &-desktop {
        display: flex;
        align-items: flex-end;
        pointer-events: fill;
        padding: $gap-sm $gap;
        background: $white;
        transition: none;

        @include media($navigation-breakpoint) {
            @include hide();
        }
    }

    &-inner {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        @extend %standard-max-width;
    }

    &-logo {
        display: block;

        img {
            width: 100%;
            height: auto;
        }


        &-container {
            max-width: pxToRem(200px);
            flex: 0 0 auto;
            margin-right: $gap * 2;
        }
    }

    &-links-container {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
    }

    &-links {
        display: flex;
        flex: 1 1 auto;
        margin-top: $space / 2;

        &-item {
            margin-right: $ui-icon-size;
            @extend %nav-label-primary;
            line-height: 1.25;

            @include media-min($navigation-breakpoint) {
                text-align: center;
            }

            &:last-child {
                margin-right: 0;
            }

            a.active {
                border-bottom: 1px solid $brand-gold;
            }
        }
    }
}

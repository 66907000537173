﻿/// <reference path="../entries/main">
.alert {
    &-container, &-global-container {
        display: flex;
        width: 100%;
        background: $brand-red;
        color: $white;
        transition: .3s ease all;
        padding: $gap-md 0;

        .x-btn {
            &::before, &::after {
                border-color: $white;
            }
        }
        a {
            color: $white;
            text-decoration-color: $white;

            &:hover {
                color: $white-72;
            }
        }

    }

    &-container[aria-hidden=true] {
        @include hide();
    }

    &-title {
        @extend %h5;
        color: $white;
    }

    &-content {
        flex-grow: 1;
        padding-right: $gap-sm;
    }

    &-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        &.has-link {
            @include media(small) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    &-cta a {
        border: 1px solid $white;
        padding-left: $gap-sm;
        transition: none;

        @include media(small) {
            margin-top: $gap-sm;
        }
    }
}

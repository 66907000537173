﻿/// <reference path="../../entries/main">

.mini-cal-month {
    &-name {
        line-height: 1;
    }
}

.ace-cal-sticky-nav {
    @include media($calendar-breakpoint-size) {
        display: flex;
        flex-direction: column-reverse;
    }
}
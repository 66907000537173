﻿/// <reference path="../../entries/main">

.rich-text, .umb-rte {

    &-max {
        max-width: $max-line-length;
    }

    * + * {
        @extend %text-top-margin;
    }

    * + li {
        margin-top: 0;
    }

    .preheader + * {
        @extend %small-top-margin;
    }

    ul,
    ol {
        padding-left: $gap;

        &.no-bullets {
            list-style-type: none;
            padding-left: 0;
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: none;

        li {
            position: relative;

            &::before {
                content: '\2022';
                position: absolute;
                top: 0.05em;
                left: -$gap / 2;
            }
        }
    }

    li {
        padding-left: $space;
    }

    .two-column,
    .three-column {
        column-count: 2;
        column-gap: $gap-md;
        column-gap: var(--gutter-h);

        @include media(medium-small) {
            column-count: 1;
        }
    }

    .three-column {
        @include media-min(large) {
            column-count: 3;
        }
    }
}

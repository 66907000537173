﻿/// <reference path="../entries/main">
$carousel-spacer: $gap;
$carousel-opacity: 0.5;

.homecarousel {

    &-wrapper {
        display: grid;
        grid-template-rows: 1fr min-content;
        grid-template-columns: 100%;
        max-width: 100%;
    }

    &-imagecarousel {
        grid-row: 1 / span 2;
        grid-column-start: 1;
        min-height: calc(100vh - #{$sticky-nav-height});
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        // Using initial value that won't be updated on scroll, but is sized to avoid the bottom menu
        min-height: calc((var(--vh-initial) * 100) - var(--nav-clearance));

        @include media($navigation-breakpoint) {
            min-height: $header-image-min-height;
        }


        .splide,
        .splide__track,
        .splide__list,
        .splide__slide {
            height: 100%;
            min-height: 100%;
        }

        &-image-container {
            position: relative;
            height: 100%;
        }
    }

    &-contentcarousel {
        grid-row-start: 1;
        grid-column-start: 1;
        margin-top: $gap-sm;

        &-carousel, .hero-content {
            display: flex;
            height: 100%;
        }

        .hero-content {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }

    &-textcarousel {
        grid-column-start: 1;
        grid-row-start: 2;

    }
}

.textcarousel {
    z-index: $z-carousel;

    &-container {
        background: $charcoal-72;
        padding: $gap 0;
        z-index: 2;

        @include media(large) {
            padding-left: $gap-md;
        }

        @include media($navigation-breakpoint) {
            padding: $gap $gap-md;
        }
    }


    &-slide {
        flex-grow: 1;
        opacity: $carousel-opacity;
        width: calc(33% - #{$carousel-spacer});


        @include media(medium) {
            width: calc(50% - #{$carousel-spacer});
        }

        @include media(tablet) {
            width: 100%;
        }

        &.is-active {
            opacity: 1;
        }

        &-content {
            color: $white;
            display: flex;

            &-container {
                flex-grow: 1;
            }
        }


        &-title {
            border-bottom: 1px solid $brand-gold-active;
            margin-bottom: $gap-sm;
            padding-bottom: $gap-sm;
            flex-grow: 1;
        }

        &-text {
            color: $white;
        }

        .slide-number {
            color: $brand-gold-active;
            margin-right: $space;
        }
    }
}

// Carousel Controls

.btn-arrows {
    display: flex;
    position: relative;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: $max-width;
    padding-right: $gap;

    @include media($navigation-breakpoint) {
        padding-right: $gap-md;
    }

    > * + * {
        margin-left: $gap;
    }

    .btn-arrow {
        position: relative;
        top: 0;
        left: 0;
        background: $brand-gold-dark;
        opacity: 1;

        &[disabled] {
            opacity: $carousel-opacity;
        }

        svg {
            fill: $white;
        }

        &::after, &::before {
            content: none;
        }

        &:hover {
            opacity: 1;
            background: $brand-gold-dark-active;

            &[disabled] {
                opacity: $carousel-opacity;
                background: $brand-gold-dark;
            }
        }
    }
}

/// <reference path="../../entries/main">

.mini-cal {
    &-column {
        position: relative;

        @include media(medium) {
            margin-top: 0;

            [data-sticky-element] {
                &,
                & + .sticky-placeholder {
                    @include hide;
                }
            }
        }
    }

    &-nav {
        position: sticky;
        align-self: flex-start;
        transition: $transition;
        top: calc(var(--nav-margin-offset) + var(--filters-height));
        padding: $gap;
        background: $white;

        @include responsive-context($gutter-map-h, $gutter-map-v) {
            margin-bottom: $responsive-variable-2;
        }
    }

    &-container {
        display: inline-block;
        max-width: 100%;
        width: 20rem;
    }

    &-footer {
        @include responsive-property('margin-top', $grid-spacing-map);
    }

    &-month-header {
        label {
            margin-bottom: $space;
        }
    }
    &-day,
    &-day-btn,
    &-day-inactive,
    &-weekday {
        @include font-size($body-font-sizes);
    }

    &-day,
    &-day-inactive {
        position: relative;

        &::before {
            content: '';
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            display: block;
        }

        & > * {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
        }
    }

    &-day,
    &-day-inactive {
        text-align: center;
    }

    &-day-inactive {
        background: $charcoal-4;
        cursor: not-allowed;
        opacity: 0.5;
    }

    &-day.mini-cal-day-today .mini-cal-day-btn {
        background: $white;
        color: $brand-gold;
        border-color: $brand-gold
    }

    &-day-btn[disabled], &-day-btn-disabled {
        cursor: not-allowed;
        background: $charcoal-16;
        color: $black;
    }

    &-weekdays,
    &-days {
        display: flex;
        flex-wrap: wrap;

        & > * {
            width: calc((99% / 7) - #{$sliver});
            margin-right: $sliver;
            margin-bottom: $sliver;
            flex: 0 1 auto;
        }

        @supports (display: grid) {
            display: grid;
            grid-template-columns: repeat(7, minmax(0, 1fr));
            grid-gap: $space;

            & > * {
                width: auto;
                margin-right: 0;
                margin-bottom: 0;
            }
        }

        max-width: 100%;
    }

    &-weekdays {
        margin-top: $gap-sm;
        margin-bottom: $space;
        grid-gap: $sliver;
    }

    &-weekday {
        text-align: center;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        font-size: $gap-sm;

        @include media-min(large) {
            min-width: 3ch;
        }

        @include media-min(x-large) {
            min-width: 4ch;
        }
    }

    &-day-btn {
        font-family: $body-font;
        padding: 0;
        cursor: pointer;
        background: $brand-gold;
        color: $white;
        border-radius: 2px;
        border: 1px solid transparent;

        & > * {
            pointer-events: none;
        }
    }

    &-day:not(.mini-cal-day-disabled):hover .mini-cal-day-btn {
        background: $white;
        color: $brand-gold;
        border-color: $brand-gold;
    }


    &-modal {
        .mini-cal-nav {
            max-width: 18.75rem;
            margin-left: auto;
            margin-right: auto;
            padding: 0;

            .modal-close {
                width: 100%;
                margin-top: $gap-sm;
            }
        }
    }
}

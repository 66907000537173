/// <reference path="../index.scss" />
///
/// Scope styles to IE or IE/Edge
/// @access public
/// @name IE/Edge
/// @content true
/// @example
///   @include ie {
///   	header {
///        height: 800px;
///     }
///   }
/// @group Miscellaneous

@mixin ie-edge {
    @at-root {
        :-ms-lang(x), & {
            @content;
        }
    }
}

//Just IE, not Edge
@mixin ie {
    @at-root {
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            & {
                @content;
            }
        }
    }
}

﻿/// <reference path="../../entries/main">

$blog-listing-breakpoint: tablet;

.blog-list {

    &-header {
        margin-bottom: $gap;
    }

    &-container {
        display: flex;
        border-top: $common-border;
        padding-top: $gap-sm;
        margin-top: $gap-lg;

        @include media($blog-listing-breakpoint) {
        margin-top: $gap;
            flex-direction: column;
        }
    }
    // SIDEBAR + FILTERS
    &-sidebar {
        width: 30%;
        border-right: 1px solid $charcoal-16;
        margin-right: $gap;
        padding-right: $gap-sm;

        @include media($blog-listing-breakpoint) {
            width: 100%;
            margin: 0;
            padding: 0;
            border-right: none;
        }

        &-filters {
            padding: $gap-sm 0 $gap;
            border-top: $common-border;
            margin-top: $gap-sm;

            @include media($blog-listing-breakpoint) {
                border-bottom: $common-border;
            }
        }

        .filter {
            @extend %un-button;
            @extend %hyperlink;
            @extend %label;

            &.active {
                color: $brand-gold;
                text-decoration: none;
            }

            &.clear {
                font-weight: $font-weight-normal;
            }
        }
    }

    &-items {
        width: 100%;
        margin: 0;
    }
    // BLOG ITEM
    &-item {
        display: flex;
        margin-bottom: $gap;


        @include media($blog-listing-breakpoint) {
            flex-direction: column;
        }


        &-content {
            flex-grow: 1;
        }

        &-title {
            padding-right: $gap;
        }

        &-image {
            @extend %cropped-16-by-9;

            &-container {
                margin-right: $gap;
                min-width: 25%;
                width: 25%;


                @include media($blog-listing-breakpoint) {
                    width: 100%;
                    margin: 0 0 $gap-sm;
                }
            }
        }

        & + & {
            padding-top: $gap;
            border-top: $common-border;
        }
    }

    &-results {
        text-align: right;
        margin-bottom: $gap;
        margin-top: -$gap-md * 2;


        @include media($blog-listing-breakpoint) {
            margin: $space 0 $gap-sm;
        }
    }
}

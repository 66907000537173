﻿/// <reference path="../entries/main">
$search-transition: .15s;

.searchbar {

    @include media-min($navigation-breakpoint) {
        background: $accent-gray;
        max-height: 0;
        overflow: hidden;

        &[aria-expanded=true] {
            padding: $gap-md $gap;
            max-height: none;
            animation: slideOpen $search-transition ease-in;

            .searchbar-container {
                animation: fadeIn $search-transition ease-in $search-transition;
                animation-fill-mode: both;
                visibility: visible;
            }
        }

        &-container {
            opacity: 0;
        }
    }

    &-container {
        position: relative;
        color: $charcoal;

        @include media($navigation-breakpoint) {
        padding: 0;
        }
    }

    &-desktop {
        @include media($navigation-breakpoint) {
            @include hide();
        }
    }




    &-input {
        border: 1px solid $charcoal-48;
        padding-left: $gap;
        padding-right: $gap-md;
        max-width: none;
        min-width: pxToRem(150px);

        &::placeholder {
            color: $black;
            font-style: normal;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    &-icon, &-submit {
        @extend %un-button;
        @extend %nav-label-primary;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: $brand-gold;


        svg {
            fill: $brand-gold;
            height: $gap-sm;
            width: $gap-sm;
        }
    }

    &-icon {
        left: $space;
        cursor: auto;
    }

    &-submit {
        display: flex;
        right: $space;

        &:hover {
            color: $brand-gold-active;

            svg {
                fill: $brand-gold-active;
            }
        }

        span {
            margin-right: $space;
        }
    }
}

// Search Icons
.site-header {

    .item-search .search-close {
        @include hide();
        margin-bottom: 0;
    }

    &.search-active .item-search {

        .search-trigger {
            @include hide();
        }

        .search-close {
            display: inline-block;
            visibility: visible;
        }
    }
}

﻿/// <reference path="../entries/main">

.theme {
    &-white {
        background: $white;
    }

    &-yellow {
        background: $accent-yellow;
    }

    &-gray {
        background: $accent-gray;
    }

    &-charcoal {
        background: $charcoal;
    }
}
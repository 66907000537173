﻿/// <reference path="../../entries/main">
$utility-icon-size: 1.25rem;
$nav-item-spacing: 1.25rem;

.utility-nav {
    display: flex;
    align-items: center;
    margin-left: $gap-sm;

    &-link {

        svg {
            fill: $brand-gold;
            width: $utility-icon-size;
            height: $utility-icon-size;
        }

        &:hover svg {
            fill: $brand-gold-active;
        }
    }

    &-item {
        position: relative;
        margin-right: $nav-item-spacing;
        display: flex;

        &:last-child {
            margin-right: 0;
        }
    }

    &-link-title {
        display: none;
    }

    .cart-not-empty-indicator {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -10px;
            height: .75rem;
            width: .75rem;
            border-radius: 100%;
            background: $brand-red;
        }
    }
}

.nav-cta {

    &-desktop {
        padding-left: $space;
        position: relative;

        @include media($navigation-breakpoint) {
            @include hide();
        }

        .btn-primary {
            font-size: var(--responsive-nav-links);
            top: -$gap-sm;
        }
    }

    &-mobile {
        @include media-min($navigation-breakpoint) {
            @include hide();
        }
    }

}

// Mobile Utility Nav
.nav-mobile .utility-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: $gap;


    &-wrapper {
        margin: 0;
    }

    &-link-title {
        display: flex;
        flex-direction: column;
        color: $white;
        @extend %small;
        font-weight: $font-weight-normal;
        text-transform: none;
        margin-top: $space;
    }

    &-item {
        text-align: center;
        margin-right: 0;
    }

}


﻿/// <reference path="../entries/main">

.highlight {
    &-heading {
        display: flex;
        justify-content: space-between;
        margin: $gap 0;
        padding-bottom: $gap-sm;
        border-bottom: $common-border;

        @include media(small) {
            flex-direction: column;
        }

        &-text {
            flex-grow: 1;

            & * + * {
                margin-top: $space;
            }
        }

        &-link {
            align-self: flex-end;
            min-width: calc(100%/3);
            text-align: right;

            @include media(small) {
                align-self: flex-start;
                text-align: left;
            }
        }
    }
}

// Base Item Setup
.highlight-item {
    padding-bottom: $gap-sm;
    margin-bottom: $gap-sm;


    @include media(tablet) {
        border-bottom: $common-border;
        margin-bottom: $gap;
    }


    &-container.columns-auto-container {
        @include media(tablet) {
            flex-direction: column;
        }

        .highlight-item {
            //override auto column
            flex: 1 1 0 !important;

            @include media(tablet) {
                width: 100%;
            }

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }
        }
    }

    &-container > div:first-child {
        margin-right: $gap-sm;
        padding-right: $gap-sm;
        border-right: $common-border;

        @include media(tablet) {
            margin-right: 0;
            padding-right: 0;
            border-right: none;
        }
    }

    &-content {
        flex-grow: 1;
        text-align: center;

        .highlight-item-pretitle {
            color: $brand-gold;
        }

        &-details {
            margin-top: $gap-sm;

            @include media(tablet) {
                margin-top: $space;
            }
        }
    }

    &-image {
        @extend %cropped-16-by-9;

        img {
            object-position: var(--focal-point-h) var(--focal-point-v);
        }

        &-container {
            margin-bottom: $gap;
        }
    }
}


// 1 Item
.layout-single {
    .highlight-item {
        display: flex;
        align-items: center;

        @include media(tablet) {
            flex-direction: column;
            align-items: flex-start;
        }

        &:first-child {
            margin-right: $gap;
            margin-bottom: 0;
            padding: 0;
            border: none;
        }

        &-content {
            flex-grow: 1;
            padding: 0 $gap;
            text-align: left;

            @include media(tablet) {
                text-align: center;
                padding: 0;
            }
        }

        &-image-container {
            min-width: 60%;

            @include media(tablet) {
                min-width: 0;
                width: 100%;
            }
        }
    }
}


// 3+ Items
.layout-multi {
    &.highlight-item-container {
        display: flex;

        @include media(tablet) {
            flex-direction: column;
        }
    }

    .highlight-item-featured-item,
    .highlight-item-featured-list {
        min-width: 50%;

        @include media(tablet) {
            width: 100%;
            min-width: none;
        }
    }
    // Sidebar Container + Items
    .highlight-item-featured-list {
        display: flex;
        flex-direction: column;


        .highlight-item {
            display: flex;
            margin-right: 0;

            @include media(tablet) {
                flex-direction: column;

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }

            @include media-min(tablet) {
                margin-bottom: 0;
                padding-bottom: 0;

                & + .highlight-item {
                    margin-top: $gap-sm;
                    padding-top: $gap-sm;
                    border-top: $common-border;
                }
            }
        }

        .highlight-item-image {


            &-container {
                min-width: 33%;
                margin-bottom: 0;

                @include media(tablet) {
                    min-width: none;
                    margin-bottom: $gap;
                }
            }
        }

        .highlight-item-content {
            margin: 0;
            text-align: left;
            padding-left: $gap;

            @include media(tablet) {
                padding: 0;
                text-align: center;
            }

            &-details {
                margin-top: $gap-sm;
            }
        }

        .highlight-item-title {
            @extend %h4;
            font-weight: normal;
        }
    }
}

﻿/// <reference path="../../entries/main">

.ace-cal {
    &-grid {

        &-days,
        &-weekdays {
            display: flex;
            flex-wrap: wrap;
            margin-right: -1px;

            & > * {
                width: calc(#{1/7 * 100% - 0.001%} - 1px);
                margin-right: 1px;
                margin-bottom: 1px;
                flex: 0 0 auto;
            }

            @supports (display: grid) {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-gap: 1px;
                //undoing IE fallbacks
                margin-right: 0;

                & > * {
                    width: auto;
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }

        &-weekdays {
            height: $weekdays-height;
            position: relative;
            background: $calendar-accent;
        }

        &-weekday {
            padding: $space 0;
            color: $charcoal-72;
            text-align: center;
        }

        &-day {
            background: $white;

            &-empty {
                background: $charcoal-4;
            }
        }

        &-month {
            @include responsive-property('margin-top', $calendar-padding-map);
            @include responsive-property('margin-bottom', $calendar-padding-map);

            &-name {
                margin-bottom: $gap-sm;
                //clear the sticky nav(s)
                /*  margin-top: -$gap - $filter-height - $weekdays-height;
                padding-top: $gap + $filter-height + $weekdays-height;*/
            }
        }
    }
}

﻿/// <reference path="../tnew">

.tn-cart-item-summary, .tn-cart-item-detail__list {
    margin-left: 0;
    margin-top: 0;
}

.tn-cart-item {
    border-color: $secondary;
    padding-top: $gap-xs;
    padding-bottom: $gap-xs;
}

.tn-cart-item-details__list {
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-top: $gap-sm;

    &-item + * {
        margin-top: $gap-xs;
    }
}

.tn-cart-item-detail__list {
    padding: $gap-xs;
    border: 1px solid $secondary;
    color: $base-text;
}

.tn-cart-totals__line-item {
    color: $base-text;
    align-items: baseline;
}

.tn-cart-item-details__list-item:first-child .tn-cart-item-detail__list {
    border-top: 1px solid $secondary;
}

.tn-cart-totals__label {
    @extend %h5;
    font-weight: $normal;

    &.tn-cart-totals__label--total {
        @extend %h3;
        text-transform: none;
        font-weight: $bold;
    }
}

.tn-cart-totals__value--total {
    font-weight: $bold;
}

.tn-cart-line-item-name {
    &, & > p {
        @extend %h2;
    }
}

.tn-cart-item-summary__property--price-total {
    @include media(medium) {
        margin: $space 0;
    }
}

.tn-cart-item__package-perfs-container {
    color: $base-text;
    border: 0;
    padding: 0;
    margin-top: $gap;
}

.tn-order-notes-component {
    .row + .row {
        margin-top: $space;
    }
}
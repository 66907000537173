﻿/// <reference path="../../../entries/main">

.tn-prod-list-item--package {
    padding: 0;
    border: 0;
    background-color: transparent;
}

.tn-prod-list-item__property--img-container {
    margin-top: 0;
    grid-row: 1 / span 2;
}

.tn-prod-list-item {
    background-color: transparent;
    padding: 0;
    border: none;

    & + .tn-prod-list-item {
        margin-top: $gap;
        padding-top: $gap;
        border-top: 1px solid $charcoal-16;
    }
}

.tn-prod-list-item__property--heading {
    a {
        color: $base-text;
        text-decoration: none;

        &:hover, &:active, &:focus {
            text-decoration: underline;
        }
    }
}

.tn-flex-package-details {
    &__locations-list {
        margin-top: 0;
    }

    &__image-wrapper {
        flex: 0 0 auto;
        margin-right: $gap;
    }
}

// FLEX
.tn-flex-package-detail-page {
    .tn-flex-layout {
        display: block;
    }
}

.tn-flex-package-details__description-container {
    h1 {
        @extend %h2;
    }
}

.tn-flex-package-details__instructions, .tn-flex-performance-group, .tn-flex-performance-group__production-list {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $base-text;
}

.tn-flex-performance-group__title {
    background: transparent;
    border-bottom: 1px solid $secondary;
    margin: 0;
    padding: $gap-xs;
}

.tn-flex-performance-group__requirements-status-container {
    padding: 10px $gap-xs;
    margin-top: 0;
}

.tn-flex-performance-group {
    border: 1px solid $secondary;
}

.tn-flex-performance-group__production-list-container {
    margin: 0;
    padding-right: $gap-xs;
    padding-bottom: $gap-xs;
}

.tn-flex-performance-group__btn-toggle-productions {
    right: $gap-xs;
    position: absolute;
    @include media(medium) {
        left: $gap-xs;
        width: calc(100% - #{$gap-xs * 2});
    }
}

.tn-flex-production__title {
    @extend %h3;
}

.tn-flex-performance-selector__border {
    border: 0;
    padding: 0;
}

.tn-flex-performance-selector__form-group {
    border: 0;
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.tn-ticket-selector {
    & > * {
        margin-bottom: 0;
        margin-top: $gap-sm;
    }
}

.tn-flex-selection {
    background-color: transparent;
    border: 1px solid $secondary;
    border-left: 5px solid $red;
    color: $base-text;
    margin-top: $gap-xs;
    min-height: 0;
}

.tn-flex-cart__primary-section {
    background-color: transparent;
    color: $base-text;
    border-color: $secondary;
}

.tn-flex-cart__primary-heading, .tn-flex-cart__secondary-heading {
    color: $base-text;
}

.tn-flex-cart__main-container {
    border-width: 1px;
    border-color: $secondary;
}

div.tn-flex-performance-selector__performance-placeholder:nth-child(n):nth-child(n) {
    margin-top: $gap-xs;
}

.tn-flex-selection__details {
    div:nth-child(2) {
        @extend %h6;
        font-weight: $normal;
    }
}

.tn-flex-production {
    @include media(large) {
        padding-left: $gap-xs;
    }
}
﻿/// <reference path="../../entries/main" />

@mixin link-helper($color: nth($link-styling, 1), $contrast: nth($link-styling, 2), $active: nth($link-styling, 3)) {
    color: $color;
    text-decoration: underline;
    text-decoration-color: $contrast;


    &:hover, &:focus {
        color: $contrast;
        text-decoration: none;
    }

    &:active {
        color: $active;
        text-decoration: none;
    }

}

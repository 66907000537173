﻿/// <reference path="../../entries/main">

.full-bleed-image {
    position: absolute;
    width: 100%;
    height: 100%;

    img,
    picture {
        @extend %cover-img;
        object-position: var(--focal-point-h) var(--focal-point-v);
    }

    & ~ * {
        position: relative;
    }
}
﻿/// <reference path="../entries/main">

.hero-video {
    &-container {
        @extend %full-cover;
        overflow: hidden;
        position: relative;
        display: flex;
    }

    &-image-container {
        @extend %full-cover;
        display: flex;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0;

        @include media($navigation-breakpoint) {
            opacity: 1;
        }
    }

    &-image-overlay {
        @extend %full-cover;
        z-index: 2;
        background: $charcoal-48;
    }

    &-container &-image-container {
        position: absolute;
    }

    &-full-bleed {
        @extend %full-cover;
        position: absolute;
    }
}

.video-full-bleed {
    @include media(tablet) {
        display: none;
    }
}

.video-full-bleed-overlay {
    background: $charcoal-48;
}

@include media-min($navigation-breakpoint) {
    .hero-video .video-full-bleed-container,
    .hero-video-image-container {
        transition: $transition;
        opacity: 0;
        pointer-events: none;
    }

    .hero-video .video-full-bleed-container {
        &:not([aria-hidden='true']) {
            opacity: 1;
        }
    }

    .hero-video-image-container {
        &:not([aria-hidden='true']) {
            opacity: 0;
        }
    }
}

﻿/// <reference path="../../entries/main">

.mnav-menu {
    &-trigger {
        @extend %un-button;
        width: $mobile-icon-size;
        height: $mobile-icon-size;

        &[aria-expanded=true] {
            span {
                &:not(.mnav-trigger-text) {
                    background-color: transparent;

                    &:before, &:after {
                        top: 0;
                        background-color: $brand-gold;
                        transition: top 0.3s ease 0s, transform 0.3s ease 0.3s, background-color 0.3s ease 0s;
                    }

                    &:before {
                        transform: rotate(-45deg);
                    }

                    &:after {
                        transform: rotate(45deg);
                    }
                }
            }
        }

        & > span:not(.visually-hidden) {
            display: block;
            height: pxToRem(3px);
            width: $mobile-icon-size;
            position: relative;
            transition: background-color 0.3s ease 0s, transform 0.3s ease 0s;
            margin-top: -$space/2;

            &:before, &:after {
                content: '';
                @extend %full-cover;
                transition: top 0.3s ease 0.3s, transform 0.3s ease 0s, background-color 0.3s ease 0s;
                transform: rotate(0);
            }

            &:before {
                top: pxToRem(-7px);
            }

            &:after {
                top: pxToRem(7px);
            }
        }
    }
    //Colors
    &-trigger {
        &[aria-expanded=true] span:not(.mnav-trigger-text) {
            &:before, &:after {
                background-color: $brand-gold;
            }
        }

        & > span:not(.visually-hidden) {
            background-color: $brand-gold;

            &:before, &:after {
                background-color: $brand-gold;
            }
        }

        &:hover, &:focus {
            &:not([aria-expanded=true]) {
                & > span:not(.visually-hidden) {
                    background-color: $brand-gold-active;

                    &:before {
                        background-color: $brand-gold-active;
                    }

                    &:after {
                        background-color: $brand-gold-active;
                    }
                }
            }
        }
    }
}

.mnav-menu-is-open {
    .mnav-menu-trigger {
        &[aria-expanded=true] {
            span {
                &:not(.mnav-trigger-text) {
                    background-color: transparent;
                }
            }
        }


        & > span:not(.visually-hidden) {
            background-color: $brand-gold;

            &:before, &:after {
                background-color: $brand-gold;
            }
        }

        &:hover, &:focus {
            &:not([aria-expanded=true]) {
                & > span:not(.visually-hidden) {
                    background-color: $brand-gold;

                    &:before {
                        background-color: $brand-gold;
                    }

                    &:after {
                        background-color: $brand-gold;
                    }
                }
            }
        }
    }
}



﻿/// <reference path="../../entries/main">
/// <reference path="../../entries/main">

$_radiocheck-size: $radiocheck-size;
$_radiocheck-padding: $space;
$_radiocheck-offset: 0.3em;
$_radiocheck-weight: 2px;
$_checkmark-width: 1rem;
$_checkmark-height: 0.5rem;
$_radio-pill-checkmark-left: 1rem;

/* Checkbox Switch */
$_switch-track-width: 2.375rem;
$_switch-track-height: 1.25rem;
$_switch-knob-size: $_switch-track-height - .125rem;
$_switch-track-top: 3px;

*:not(label) > input[type="checkbox"],
*:not(label) > input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    overflow: hidden;
    opacity: 0;

    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
}

input[type="checkbox"],
input[type="radio"] {
    &:focus {
        & + label,
        & + input[type="hidden"] + label {
            @include focused;
        }
    }

    & + label,
    & + input[type="hidden"] + label {
        @extend %radio-checkbox-label;
        @include focus;
        position: relative;
        display: inline-block;
        margin-left: 0;

        &::before {
            content: '';
            border: $_radiocheck-weight solid;
            display: inline-block;
            position: absolute;
            left: 0;
            top: $_radiocheck-offset;
        }
    }

    &:checked + label,
    &:checked + input[type="hidden"] + label {
        &::after {
            content: '';
            position: absolute;
            display: inline-block;
            border-color: $brand-gold;
        }
    }
}

// SIZING
input[type="radio"],
input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {
        padding-left: $_radiocheck-size + $_radiocheck-padding;

        &::before {
            width: $_radiocheck-size;
            height: $_radiocheck-size;
        }
    }
}


// CHECKBOX
input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {
        &::after {
            border: $_radiocheck-weight solid;
            border-width: 0 0 $_radiocheck-weight $_radiocheck-weight;
            transform: rotate(-45deg);
            display: block;
            // Manually adjust based on other sizing and check shape
            top: 0.5em;
            left: 0.1875em;
        }

        &::before {
            border-radius: 3px;
            color: $brand-gold;
        }
    }
}

// SIZING
input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {
        &::after {
            width: $_radiocheck-size / 1.5;
            height: $_radiocheck-size / 3;
        }
    }
}

// CHECKBOX SWITCH
input[type="checkbox"] {
    &.switch {
        & + label,
        & + input[type="hidden"] + label {
            display: block;
            padding-left: 0;
            padding-right: $_switch-track-width + $space;

            &::before {
                border: none;
                width: $_switch-track-width;
                height: $_switch-track-height;
                border-radius: 100px;
                background-color: $brand-gold;
                left: auto;
                right: 0;
                top: $_switch-track-top;
            }

            &::after {
                border: none;
                transform: none;
                left: auto;
                right: 1px;
                top: $_switch-track-top + 1px;
                border-radius: 100px;
                background-color: $brand-cool-gray;
                height: $_switch-knob-size;
                width: $_switch-knob-size;
                display: block;
                transform: translateX(-1.125rem);
                content: '';
                position: absolute;
                transition: $transition;
            }
        }

        &:checked {
            & + label,
            & + input[type="hidden"] + label {
                &::before {
                    background-color: $brand-gold;
                }

                &::after {
                    background-color: $brand-gold;
                    transform: translateX(0);
                }
            }
        }
    }
}

// RADIO
input[type="radio"] {
    & + label,
    & + input[type="hidden"] + label {
        &::before {
            border-radius: 50%;
            border-color: $brand-gold;
        }

        &::after {
            border: $_radiocheck-weight solid transparent;
            border-radius: 50%;
            transform: scale(0.5);
            top: $_radiocheck-offset;
            left: 0;
        }
    }
}

// SIZING
input[type="radio"] {
    & + label,
    & + input[type="hidden"] + label {
        &::after {
            width: $_radiocheck-size;
            height: $_radiocheck-size;
        }
    }
}

// RADIO PILLS
input[type="radio"].radio-btn {
    & + label,
    & + input[type="hidden"] + label {
        padding: $button-form-padding;
        padding: var(--button-form-padding);
        border-radius: 100px;
        cursor: pointer;

        @include media(small) {
            width: 100%;
        }

        &::before {
            content: '';
            border: $_radiocheck-weight solid;
            border-width: 0 0 $_radiocheck-weight $_radiocheck-weight;
            transform: rotate(-45deg);
            // Manually adjust based on other sizing and check shape
            top: 0.85em;
            left: $_radio-pill-checkmark-left;
            border-radius: 0;
            width: $_checkmark-width;
            height: $_checkmark-height;
            opacity: 0;
            display: block;
            transition: $transition;
        }

        &::after {
            display: none;
        }
    }

    &:checked {

        & + label,
        & + input[type="hidden"] + label {
            @include media-min(small) {
                padding-left: $button-form-padding-h + $_checkmark-width + $_radio-pill-checkmark-left;
            }

            &::before {
                opacity: 1;
            }
        }

        & + label::after {
            background-color: $brand-gold;
        }
    }
}

// RADIO BACKGROUND
input[type="radio"] {
    & + label,
    & + input[type="hidden"] + label {
        &::after {
            background: $brand-gold;
        }
    }
}

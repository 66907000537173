﻿/// <reference path="../entries/main">


// STRUCTURE
.fwcta {

    &-inner {
        display: flex;

        @include media-min(small) {
            justify-content: space-between;
            align-items: center;
        }
    }

    &-content {
        &-container {
            text-align: center;
            margin: 0 auto;
        }

        &-pretitle {
            @extend %h6;
            color: $brand-gold-dark;
            margin-bottom: $space;
        }

        &-title {
            margin-bottom: $gap;

            @include media(small) {
                margin-bottom: $gap-sm;
            }
        }

        &-btn {
            @extend %btn-secondary;
            margin-top: $space;


            &-container {
                @include responsive-context($medium-vertical-gap-map) {
                    margin-top: $responsive-variable;
                }

                & > * + * {
                    margin-left: $space;
                }
            }
        }
    }

    &-image-container {
        width: 100%;
    }
}


// Layout Specific
@include media(medium) {
    .layout-8-4, .layout-4-8 {
        &.reversed {
            flex-direction: column-reverse;
        }

        .fwcta-content-container {
            @include responsive-context($component-gutter-map) {
                margin-top: $responsive-variable;
                margin-bottom: $responsive-variable;
            }
        }
    }
}

// Themes
.theme-charcoal.fwcta {
    color: $white;

    .fwcta-content-title {
        color: $white;
    }

    .fwcta-content-btn {
        @extend %btn-primary;
    }

}

.theme-yellow.fwcta {
    .fwcta-content-pretitle {
        color: $charcoal-72;
    }
}

﻿/// <reference path="../../entries/main">

.button-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$space;
    margin-bottom: -$space;
    @include media(small) {
        flex-direction: column;
        align-items: stretch;
    }
    &-item {
        margin-right: $space;
        margin-bottom: $space;
    }

    input[type="radio"],
    input[type="checkbox"] {
        & + label {
            @extend %btn;

            &::before,
            &::after {
                content: none;
            }
        }

        &:checked + label {
            background-color: $brand-gold;
            color: $white;
            border-color: $brand-gold;
        }
    }
}

﻿// Full Bleed image that works on IE and modern browsers

%cover-img {
    @extend %full-cover;
    height: auto;

    @supports(object-fit: cover) {
        height: 100%;
        object-fit: cover;
    }
}

%cropped-16-by-9 {
    width: 100%;
    padding-bottom: ratioToPercent(16, 9);
    overflow: hidden;
    position: relative;
    height: 0;

    img, picture {
        @extend %cover-img;
    }
}
%cropped-1-by-1 {
    width: 100%;
    padding-bottom: ratioToPercent(1, 1);
    overflow: hidden;
    position: relative;
    height: 0;

    img, picture {
        @extend %cover-img;
    }
}

%background-with-focal-point {
    background-repeat: no-repeat;
    background-size: cover;
}
﻿/// <reference path="../../entries/main">

// Form wrapper that applies wrapping grid styles for all child items
.#{$prefix}form {
    display: flex;
    flex-wrap: wrap;

    & > * {
        flex: 0 0 auto;
        width: 100%;

        &:not([class*="ace-form"]) {
            @include responsive-context($gutter-map-h, $all-breakpoints: true) {
                width: calc(100% - #{$responsive-variable});
            }
        }
    }

    @include responsive-context($gutter-map-h, $paragraph-margin-map, $all-breakpoints: true) {
        max-width: $max-input-width + $responsive-variable;
        margin-right: -$responsive-variable;
        margin-bottom: -$responsive-variable-2;

        & > *:not(:empty) {
            margin-right: $responsive-variable;
            margin-bottom: $responsive-variable-2;
        }

        &-item-full {
            max-width: $max-input-width + $responsive-variable;
            margin-top: $responsive-variable-2;
            margin-bottom: $responsive-variable-2;
        }

        & > .#{$prefix}form {
            @include responsive-context($gutter-map-h, $paragraph-margin-map, $all-breakpoints: true) {
                margin-right: -$responsive-variable;
            }
        }
    }

    @include media(tablet) {
        &-item-full {
            max-width: none;
        }
    }

    @include media-range(small, tablet) {
        max-width: calc(50% + #{$responsive-variable});
    }

    &-section {
        border-top: 1px solid $brand-gold;

        @include responsive-context($gutter-map-h) {
            padding-top: $responsive-variable;
            padding-bottom: $responsive-variable;
        }
    }
}

.#{$prefix}form-nomax {
    .#{$prefix}form {
        max-width: none;
    }
}

.#{$prefix} {
    &form-item,
    &form-item-sm,
    &form-item-full,
    &form-item-xs {
        & > * + * {
            margin-top: $sliver;
        }
    }

    &form-item {
        @include responsive-context($gutter-map-h, $all-breakpoints: true) {
            width: calc(100% - #{$responsive-variable});

            &-sm {
                width: calc(50% - #{$responsive-variable});
            }

            &-xs {
                width: calc(33.33% - #{$responsive-variable});
            }
        }

        &-sm {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            & > * {
                flex: 0 0 auto;
            }
        }
    }

    &input-label,
    &form-item label,
    &form-item-sm label {
        @extend %flex-between;
        align-items: baseline;
    }

    &form-item-sm, &form-item-xs {
        label {
            @include media(tablet) {
                display: block;

                & > * {
                    display: block;
                }
            }
        }
    }
}

form label {
    @extend %flex-between;
    align-items: baseline;
    max-width: $max-input-width;
}

// Input list
.#{$prefix}input-list {

    .reveal-panel {
        @include responsive-context($paragraph-margin-map) {
            margin-top: $responsive-variable;
            margin-bottom: $responsive-variable;
        }
    }

    & > * + * {
        @extend %medium-top-margin;
    }

    &-item > label {
        padding-left: calc(#{$radiocheck-size} + #{$radiocheck-padding});
    }

    &-long {
        column-count: 2;

        @include media(mobile) {
            column-count: 1;
        }
    }

    &-long &-item {
        display: inline-block;
        width: 100%;

        &:first-child {
            @extend %medium-top-margin;
        }
    }

    &-item {
        padding: $gap;
        border-radius: $border-radius;
        border: 1px solid $brand-gold;

        @include media(small) {
            padding: $gap-md;
        }
    }
}

﻿/// <reference path="../entries/main">

$upcoming-events-breakpoint: "medium";

// Header
.upcoming-event-header {
    align-items: center;
    padding-bottom: $gap-sm;

    &-description {
        margin: 0;
    }
}

// Event
.upcoming-event {
    @include responsive-property('padding-top', $gutter-map-h);
    @include responsive-property('padding-bottom', $gutter-map-h);


    @include media-min($upcoming-events-breakpoint) {
        display: flex;
        flex-wrap: wrap;
    }

    &-padded {
        @extend %standard-padding-h;
    }

    &-card {
        width: 100%;
        border-top: $common-border;
    }


    &-half {
        @include media($upcoming-events-breakpoint) {

            & + & {
                margin-top: $gap-sm;
            }
        }

        @include media-min($upcoming-events-breakpoint) {
            display: flex;
            width: 50%;

            & + & {
                @include responsive-context($gutter-map-h) {
                    width: calc(50% - #{$responsive-variable});
                    padding-left: $responsive-variable/2;
                    margin-left: $responsive-variable/2;
                    flex-grow: 1;
                }
            }

            & > * {
                width: 100%;
            }

            & > * + * {
                @include responsive-context($gutter-map-h) {
                    padding-left: $responsive-variable;
                }
            }

            &-padded-right {
                @include responsive-context($gutter-map-h) {
                    padding-right: $responsive-variable;
                }
            }
        }
    }

    &-content {
        display: flex;

        @include media($upcoming-events-breakpoint) {
            flex-direction: column-reverse;
        }
    }

    &-price {
        // value set by Figma
        min-width: 11rem;

        @include media-min($upcoming-events-breakpoint) {
            text-align: right;
        }
    }

    &-details {
        flex-grow: 1;

        &-heading {
            display: flex;
            flex-direction: column;
            margin-bottom: $gap-sm;
        }


        &-title {
            color: $charcoal;
            text-decoration-color: $brand-gold;
        }

        &-category {
            color: $brand-gold;
        }
    }

    &-date {
        margin-bottom: $space;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @include media-min($upcoming-events-breakpoint) {
            width: 20%;
            min-width: 13rem;
            margin: 0;
            display: block;
            text-align: right;
        }

        span {
            //override heading styles
            display: inline;
        }

        & > * {
            margin: 0;
        }
    }

    &-image {
        flex-grow: 1;

        &-container {
            @extend %cropped-16-by-9;
        }

        @include media-min($upcoming-events-breakpoint) {
            padding-left: $gap;
        }
    }

    &-content {
        flex: 1 1 auto;
    }

    &-ctas {
        margin-top: $gap;

        & > * + * {
            margin-left: $space;
        }
    }
}

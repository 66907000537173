﻿/// <reference path="../entries/main">

%standard-padding-h {
    @include responsive-context($standard-padding-map) {
        padding-left: $responsive-variable;
        padding-right: $responsive-variable;
    }
}

%standard-padding-v {
    @include responsive-context($block-margin-map) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}

%standard-margin-v {
    @include responsive-context($block-margin-map) {
        margin-top: $responsive-variable;
        margin-bottom: $responsive-variable;
    }
}

%medium-margin-v {
    @include responsive-context($gutter-map-v) {
        margin-top: $responsive-variable;
        margin-bottom: $responsive-variable;
    }
}

%component-spacing-v {
    @include responsive-context($component-top-margin-map) {
        margin-top: $responsive-variable;
    }

    @include responsive-context($component-bottom-margin-map) {
        margin-bottom: $responsive-variable;
    }
}

%component-spacing-h {
    @include responsive-context($component-gutter-map) {
        margin-left: $responsive-variable;
        margin-right: $responsive-variable;
    }
}

%component {
    @extend %component-spacing-v;
}

%standard-side-gutters {
    padding: 0 $gap;

    @include media($navigation-breakpoint) {
        padding: 0 $gap-md;
    }
}

%standard-max-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
}

%full-cover {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

%standard-padding-and-max-width {
    @extend %standard-max-width;
    @extend %standard-padding-h;
    @extend %standard-padding-v;
}

// Pattern for a simple 2 column layout that has responsive margins and collapses to 1 column at the medium breakpoint
%simple-2-col-container, %simple-3-col-container {
    @include media-min(tablet) {
        display: flex;
        flex-wrap: wrap;
    }
}

%simple-2-col-item {
    @include responsive-context($grid-spacing-map, $block-margin-map) {
        margin-right: $responsive-variable;
        margin-bottom: $responsive-variable-2;
        width: calc(50% - #{$responsive-variable / 2});
    }

    @include media-min(tablet) {
        flex: 0 0 auto;

        &:nth-child(even) {
            margin-right: 0;
        }

        &:last-child, &:nth-last-child(2):nth-child(odd) {
            margin-bottom: 0;
        }
    }

    @include media(tablet) {
        width: 100%;
        margin-right: 0;
    }
}
%simple-3-col-item {
    @include responsive-context($grid-spacing-map, $block-margin-map) {
        margin-right: $responsive-variable;
        margin-bottom: $responsive-variable-2;
        width: calc(33% - #{$responsive-variable / 2});
    }

    @include media-min(tablet) {
        flex: 0 0 auto;

        &:nth-child(even) {
            margin-right: 0;
        }

        &:last-child, &:nth-last-child(2):nth-child(odd) {
            margin-bottom: 0;
        }
    }

    @include media(tablet) {
        width: 100%;
        margin-right: 0;
    }
}

%card-padding {
    @include responsive-context($card-padding-h, $card-padding-v) {
        padding: $responsive-variable-2 $responsive-variable;
    }
}

%grey-card {
    @extend %card-padding;
    background-color: $brand-gold;
    border-radius: $border-radius;
}

%white-card {
    @extend %card-padding;
    border-radius: $border-radius;
    background-color: $white;
}

%white-card-bordered {
    @extend %white-card;
    border: 1px solid $brand-gold;
}

%small-card-padding {
    @include responsive-context($gutter-map-h, $gutter-map-v) {
        padding: $responsive-variable-2 $responsive-variable;
    }
}

%two-thirds {
    @include media-min(tablet) {
        width: calc(#{$one-third * 2} - #{$max-gutter-width/3});
        width: calc(#{$one-third * 2} - (calc(var(--gutter-h)/3)));
    }
}

// Flexbox helpers
@mixin flex-between {
    display: flex;
    justify-content: space-between;

    & > * {
        margin-top: 0;
        flex: 0 1 auto;

        &:first-child {
            margin-right: $space;
        }
    }
}

%flex-between {
    @include flex-between;
}
%flex-center {
    @extend %flex-between; 
    justify-content:center;
}

%flex-column-between, .flex-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    & > * {
        flex: 0 1 auto;

        &:first-child {
            margin-bottom: $space;
        }
    }
}


%flex-fiftyfifty {
    display: flex;
    flex-wrap: wrap;

    & > * {
        flex: 0 1 auto;
        max-width: 100%;
        margin-top: 0;

        @include responsive-context($gutter-map-h) {
            width: calc(50% - #{$responsive-variable / 2});
            margin-right: $responsive-variable;

            &:nth-child(even) {
                margin-right: 0;
            }

            &:nth-child(2) ~ * {
                margin-top: $responsive-variable;
            }
        }

        @include media(mobile) {
            width: 100%;
        }
    }
}

%responsive-column-gap {
    @include responsive-context($gutter-map-h) {
        column-gap: $responsive-variable;
    }
}

// Responsive grid gaps based on gutter values
%responsive-grid-gap {
    @include responsive-context($gutter-map-h, $gutter-map-v) {
        grid-column-gap: $responsive-variable;
        grid-row-gap: $responsive-variable-2;
    }
}

// Responsive 12 column grid that goes edge to edge
%responsive-grid-columns {
    @include responsive-context($standard-padding-map, $gutter-map-h) {
        $_column-width: 0;
        $_margin-width: 0;

        @if $responsive-breakpoint == default or (map-get($breakpoints, $responsive-breakpoint) > $x-large-media-query-size) {
            $_column-width: minmax(auto, $max-column-width);
            $_margin-width: minmax(#{$responsive-variable - $responsive-variable-2}, 1fr);
        }

        @else {
            $_column-width: minmax(0, 1fr);
            $_margin-width: #{$responsive-variable - $responsive-variable-2};
        }

        grid-template-columns: $_margin-width repeat(12, $_column-width) $_margin-width;
    }
}

﻿/// <reference path="../../entries/main">

.ace-cal {
    &-subnav {
        @include media-min($calendar-breakpoint-size) {
            // this button must keep existing in the DOM for functionality, so just hide with css
            @include hide;
        }

        &-item {
            display: flex;
            align-items: center;

            &:last-child {
                margin-right: 0;
            }

            @include ie {
                button {
                    flex-shrink: 0;
                }
            }
        }
    }
}

// MONTH DROPDOWN
.ace-cal {
    &-month-dropdown {
        flex: 1 1 auto;
    }
}

// TOGGLES
.ace-cal {
    &-toggles {
        flex: 0 0 auto;
        position: absolute;
        top: -$gap-md;
        height: $gap-md;

        &-container {
            position: relative;
            height: $gap-md;
        }

        .label {
            border-bottom: 2px solid transparent;
            padding-bottom: $space;

            &:first-child {
                margin-right: $gap-sm;
            }

            &[disabled] {
                border-color: $brand-gold;
            }
        }
    }
}

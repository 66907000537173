﻿/// <reference path="../../entries/main">

html {
    // These vh custom properties are handled in vhUnitFix.ts to normalize vh units on mobile browsers with bottom menus.
    // _____________
    // |           | Regular vh units: based on FULL HEIGHT of page, including menu area, does not change
    // |           | --vh-initial: based on SHORTER HEIGHT of page, subtracting menu area, does not change
    // |           | --vh: based on CURRENT HEIGHT of page, changes when menu is shown/hidden
    // |           |
    // |           |
    // |           |
    // |-----------|
    // | MENU AREA |
    // |___________|
    --vh: 1vh;
    --vh-initial: 1vh;
    // Initial value for nav, to prevent layout shift before the accurate height is measured in js
    --nav-clearance: calc(#{$sticky-nav-height} * 2);
    // Initial value for the offset needed for on-page link targets to avoid being hidden by the nav
    --nav-margin-offset: #{$sticky-nav-height};
    //Header Images
    --header-image-height: #{$header-image-min-height};

    @include media($navigation-breakpoint) {
        --nav-clearance: #{$sticky-nav-height-mobile};
        --nav-margin-offset: #{$sticky-nav-height-mobile};
    }

    @include media(tablet) {
        --header-image-height: #{$header-image-min-height-mobile};
    }
    --filters-height: 0;
}

body {
    --button-form-padding-h: #{$button-form-padding-h};
    --button-form-padding-v: #{$button-form-padding-v};
    --button-form-padding: #{$button-form-padding};
    --input-form-padding: #{$input-form-padding};
    --square-button-size: #{$square-button-size};
    // these values respond to themes
    --text-color: #{$body-color};
    --background-color: #{$white};
    --focus-ring: #{$focus-color};
    --light-color: #{$brand-cool-gray};
    --light-text: #{$brand-gold};

    @include media(small) {
        --button-form-padding-h: 0.625rem;
        --button-form-padding-v: 0.75rem;
        --button-form-padding: 0.625rem 0.75rem;
        --input-form-padding: 0.625rem 0.75rem;
        --square-button-size: calc(#{1rem * $body-line-height + (0.625rem * 2)} + #{$button-form-border * 2});
    }

    @include responsive-context($gutter-map-h, $gutter-map-v) {
        --gutter-h: #{$responsive-variable};
        --gutter-v: #{$responsive-variable-2};
    }
}

﻿/// <reference path="../entries/main">

$bio-image-max-width: 10rem;
$bio-image-max-width-mobile: 8rem;

// GRID AND CONTAINER STRUCTURE
.bio {
    &-container {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        margin-top: $gap;
        margin-bottom: $gap-sm;
    }

    &-item {
        width: calc(100%/3);

        @include media(tablet) {
            width: 50%;
        }

        @include media(small) {
            width: 100%;
        }
    }
}

// STYLING
.bio {

    &-heading {
        text-align: center;
    }

    &-item {
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: column;
        margin: $gap-sm 0;

        &-image {
            flex: 0 0 auto;
            width: 100%;
            max-width: $bio-image-max-width;

            @include media(small) {
                max-width: $bio-image-max-width-mobile;
            }
        }

        &-content {
            flex: 0 1 auto;
            max-width: $max-line-length / 2;
            text-align: center;
            margin-top: $gap-sm;
        }


        &-img {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
            border-radius: 999rem; //arbitrarily big number to guarantee circle


            img {
                position: absolute;
                object-fit: cover;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-position: var(--focal-point-h) var(--focal-point-v);
            }
        }

        &-role {
            color: $brand-gold;
        }

        &-info {
            margin-top: $space;
        }

        &-additional {
            &-cta {
                @extend %btn-plus-minus;
                @include button-reveal($brand-gold, $brand-gold);
                padding-right: $gap-sm;

                &:after {
                    right: -$gap-sm;
                    border: none;
                }
            }

            &-content {
                margin-bottom: $gap;
                padding: 0 $gap-sm;
                text-align: center;
            }
        }
    }
}
// Load More Styling
.bios-reveal {
    &-cta {
        margin: 0 auto;
        display: block;
        @extend %btn-plus-minus;
        @include button-reveal($white, $white);
    }
}

// Themes
.theme-charcoal.bio,
.theme-charcoal.contact {
    color: $white;

    .bio-heading > *,
    .bio-item-title,
    .bio-heading-title {
        color: $white;
    }


    .bio-item-role, .bio-item-additional-cta {
        color: $accent-yellow;
    }

    .bio-item-additional-cta {
        @extend %btn-plus-minus;
        @include button-reveal($accent-yellow, $accent-yellow);
    }
}

.theme-yellow.bio {
    .bio-item-additional-cta {
        @extend %btn-plus-minus;
        @include button-reveal($charcoal, $charcoal);
    }
}

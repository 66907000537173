﻿/// <reference path="../../entries/main">
.ace-cal {
    &-back-to-top {
        @include responsive-property('right', $calendar-padding-map);
        bottom: $gap;
        position: fixed;
        z-index: 110;
        transition: $transition opacity;
        opacity: 0;
        pointer-events: none;

        &[data-show-button="true"] {
            opacity: 1;
            pointer-events: fill;
        }

        &:after {
            @include icon-arrow($_bgcolor: $white);
            transform: translateY(-50%) rotate(-90deg);
            border-left: none;
            border-top: 1px solid rgba(255, 255, 255, 0.24);
        }

        @include media(tablet) {
            padding: 1.25rem;

            span {
                display: none;
            }

            &:after {
                border-top: none;
            }
        }
    }
}
﻿/// <reference path="../../entries/main">

%calendar-edge-padding {
    @include responsive-property('padding-left', $calendar-padding-map);
    @include responsive-property('padding-right', $calendar-padding-map);
}

.calendar-page-header {
    @include responsive-property('margin-top', $block-margin-map);
    @include responsive-property('margin-bottom', $block-margin-map);
}

.ace-cal {
    @include responsive-property('padding-bottom', $block-margin-map);
    background: $calendar-accent;

    select {
        @include select-dropdown($brand-gold);
    }
}

html[data-calendar-loading="true"] {
    overflow: hidden;
}

html:not([data-calendar-loading="true"]) {
    scroll-behavior: smooth;
}

@media (prefers-reduced-motion: true) {
    html:not([data-calendar-loading="true"]) {
        scroll-behavior: auto;
    }
}

ul[class*="ace-cal"] {
    list-style-type: none;
}

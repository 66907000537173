﻿/// <reference path="../tnew">

.btn-block {
    width: auto;
}

.tn-account-login-page {
    .tn-register-button {
        margin-bottom: 0;
    }

    .tn-create-heading ~ .row {
        margin-top: $gap-sm;
    }
}

.form-group {
    max-width: $max-input-width;
}

.tn-interests {
    h2 {
        margin-top: $gap;
    }

    .form-group {
        display: block;
    }

    .checkbox label input {
        margin-top: -2px;
    }

    .checkbox {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}


#tn-password-update-form .form-group {
    .form-group {
        display: block;
    }
}

.tn-required-field {
    color: $red;
    @extend %small;
}

.alert {
    padding: $gap-xs;
}

// Register page
#tn-account-register-form {
    padding-top: 1px //prevent margin collapse
}

.tn-interests {
    h2:only-child {
        display: none;
    }
}

.tn-account-create-page {
    .row {
        margin-top: $gap-sm;
    }
}

// More forms
.has-error .checkbox, .has-error .checkbox-inline, .has-error.checkbox-inline label, .has-error.checkbox label, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.radio-inline label, .has-error.radio label {
    color: $red;
}

.has-error .form-control {
    border-color: $red;
}

// Account update
.tn-account-update-page, .tn-account-interests-page, .tn-account-create-brief-page {
    .row {
        margin-top: $gap-sm;
    }
}

.tn-account-tab-navigation {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: $gap;
    margin-bottom: $gap;
}

.btn.tn-btn-grouped ~ .btn.tn-btn-grouped {
    margin-top: 0;

    @include media-min(mobile) {
        margin-top: 1rem;
    }
}

.nav-tabs > li {
    &.active {
        a {
            text-decoration: none !important;
            outline: none !important;
        }
    }

    & > a {
        @include media-min(tablet) {
            text-decoration: none;
        }

        @include media(tablet) {
            border: 1px solid $accent-gray !important;
        }
    }
}

[role="button"] {
    display: inline-block;
}

.tn-account-login-forgot-login-link {
    @extend %btn-link-primary;
}

.tn-create-brief-heading {
    margin: $gap 0 $gap-sm;
}

//Update password
.tn-account-update-password-page {
    .form-group {
        max-width: none;
        display: block;
    }
}

.tn-update-password-component {
    h2 {
        margin-bottom: $gap;
    }
}

//Giving History
.tn-giving-history-page {
    .tn-giving-history__amount {
        margin-top: 0;
    }
}

//Digital Content
.tn-digital-content {
    @include media-min(small) {
        flex-wrap: wrap;
    }
}

//Memberships
.tn-membership-summary__heading {
    @extend %h3; 
}
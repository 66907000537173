﻿/// <reference path="../../entries/main">

$primary: #333333;
$text-on-primary: #ffffff;
$secondary: $charcoal-16;
$text-on-secondary: #333333;
$base-text: #000000;
$red: #be0f0f;

$max-line-length: 70ch;
$max-input-width: 30rem;

$bold: 700;
$normal: 400;

$space: 0.5rem;
$gap-xs: $gap-sm;


@import 
'styles/_payment.scss',
'styles/_print.scss',
"mixins/checkbox-radio-fixes"
;


html body .tnew-container {
    @import 
    'styles/_buttons-and-type.scss',
    'styles/_misc.scss',
    'styles/_event-page.scss',
    'styles/_syos.scss',
    'styles/_cart.scss',
    'styles/_subnav.scss',
    'styles/_register-and-account.scss',
    'styles/_subs.scss',
    'styles/_forms.scss',
    'styles/_event-listing.scss'
    ;
}


// Styles needed outside of 'html body' prefix
.tnew-container {
    @extend %standard-max-width;
    @extend %standard-padding-v;
}

.tnew-wrapper {
    @extend %standard-side-gutters;
}

body {
    @extend %body;
}

fieldset {
    margin-top: 0;
}

ul {
    margin-left: 0;
}

.tn-additional-information, .tn-flex-package-details__description-container {
    ul {
        margin-left: $gap-sm;
    }
}

.tn-prod-list-item__property--img-container {
    @extend %cropped-16-by-9;
}

html body .tnew-container main {
    padding: 0;
    & > *:first-child.tn-header-component {
        margin-top: 0;
    }
}

.modal {
    .checkbox, .radio {
        @include checkbox-radio-fixes();
    }
}

.alert-info, .alert-danger {
    background: $brand-red;
    color: $white;
    border-radius: 0;
}

﻿/// <reference path="../tnew">

.tn-payment-modal-open {
    // TNEW handles the payment modal overlay weirdly, and it conflicts
    // with the Uskinned site wrapper so this is a workaround to fix that.
    #site {
        z-index: auto;
    }

    .modal-backdrop.in {
        opacity: 0.5;
    }
}

// Confirmation
.tn-order-address-information-component, .tn-receipt-component {
    margin-top: $gap;

    & > * {
        margin: 0;

        @include media-min(medium) {
            display: flex;
            justify-content: space-between;
        }

        &::before, &::after {
            content: none;
        }

        & > * {
            float: none;
            flex: 0 0 auto;
            padding: 0;
            left: auto;
            right: auto;

            @include media-min(medium) {
                width: calc(50% - #{$gap / 2});
            }

            @include media(medium) {
                width: 100%;
                margin-bottom: $gap;
            }
        }

        & > * > * {
            float: none;
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }
}

.tn-receipt-component {
    & > * {
        flex-direction: row-reverse;
    }
}

.tn-cart-component {
    margin-top: $gap;
}

.tn-gift-certificates-redemption-component {
    .row {
        margin: 0;
    }

    *[class*="col"] {
        padding: 0;
    }
}

.tn-payment-component, .tn-order-notes-component, .tn-checkout-survey-component, .tn-payment-submit-component {
    margin-top: $gap;
}

.input-group-addon {
    // $ before input, somewhat arbitrary styles to get it to fit well
    padding: 0.6rem 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: $base-text;
    position: relative;
    z-index: 3;
    font-size: 1.25rem;
    width: 0;
    margin-left: 0.5rem;

    & + input {
        padding-left: 1.5rem;
        margin-left: -0.5rem;
    }
}

.tn-payment-page input {
    min-width: 100px !important;
}
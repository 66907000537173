﻿/// <reference path="../../entries/main">
$button-size: 1.5rem !default;

.ace-cal {
    &-filters {
        &-modal {
            @include responsive-property('padding', $calendar-padding-map);

            &-trigger-container {
                width: 100%;

                @include media-min($calendar-breakpoint-size) {
                    // this button must keep existing in the DOM for functionality, so just hide with css
                    @include hide;
                }
            }

            &-bg {
                position: fixed;
                background: $charcoal-72;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 100;
            }

            &-button {
                padding: 0.75rem;
                background: $accent-gray;
                display: flex;
                align-content: center;
                justify-content: center;

                svg {
                    fill: $brand-gold;
                }
            }

            &-container {
                z-index: 150;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                min-height: 100vh;
                opacity: 1;
                overflow: auto;
            }

            &-inner {
                @include responsive-property('padding', $standard-padding-map);
                background: $white;
                position: relative;
            }

            &-close {
                position: absolute;
                top: $space;
                right: $space;
                background: transparent;
                border: 0;
                border-radius: 0;
                padding: 0;
                width: $button-size;
                height: $button-size;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    border-top: $ui-icon-thickness solid $primary-calendar-dark;
                    transition: $transition;
                    position: absolute;
                    width: 100%;
                    top: calc(50% - #{$ui-icon-thickness / 2});
                    transform-origin: center center;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(135deg);
                }

                &:hover,
                &:active,
                &:focus {
                    background: transparent;

                    &:before,
                    &:after {
                        border-color: $secondary-calendar-medium;
                    }
                }
            }
        }
    }
}

﻿/// <reference path="../entries/main">

$_youtube-ui-offset: 15rem;

.video-full-bleed {
    @extend %full-cover;

    &[aria-hidden="true"] {
        display: none;
    }

    .video-iframe {
        padding-bottom: 0;
    }

    iframe {
        width: 100%;
        flex: 0 0 auto;
    }

    video,
    iframe[data-hero-video] {
        // As of now, videos are purely centered. This is where to change that if needed
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-overlay {
        @extend %full-cover;
    }

    &-controls {
        position: absolute;
        display: flex;
        align-items: center;
        pointer-events: fill;
        z-index: $z-video-controls;

        & > * + * {
            margin-left: $space;
        }
        .btn-icon-round::after {
            content: none;
        }
    }

    @include responsive-context($standard-padding-map, $grid-spacing-map) {
        &-controls {
            left: auto;
            right: $responsive-variable;
            bottom: $responsive-variable-2;
        }
    }

    &-container {
        @extend %full-cover;
        display: flex;
        justify-content: center;
    }
}

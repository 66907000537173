﻿/// <reference path="../../entries/main">

@mixin max-width-offset($property: "padding-left", $space-offset: 0, $additional-spacing-amount: 0, $spacing-map: $standard-padding-map) {
    //Offset an elment to simulate the max-width margin-auto appearance;
    //Used on the gallery block
    @if($space-offset != 0) {
        @include responsive-context($spacing-map) {
            #{$property}: calc(((100vw - #{$max-width})/2) + #{$responsive-variable - $space-offset});
        }
    }
    @elseif($additional-spacing-amount != 0) {
        @include responsive-context($spacing-map) {
            #{$property}: calc(((100vw - #{$max-width})/2) + #{$responsive-variable} + #{$additional-spacing-amount});
        }
    }
    @else {
        @include responsive-context($spacing-map) {
            #{$property}: calc(((100vw - #{$max-width})/2) + #{$responsive-variable});
        }
    }
}
﻿/// <reference path="../../entries/main">

.inline-input-form {
    display: flex;
    max-width: $max-input-width;

    & > button {
        flex: 0 0 auto;
    }

    & > input {
        flex: 1 1 auto;
    }
}

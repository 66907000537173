﻿/// <reference path="../tnew">

.tn-subnav-component {
    padding: $gap-xs 0;
    margin-bottom: $gap-xs;

    .tn-link, .tn-account-info-link, .tn-logout-link {
        text-decoration: none;
    }

    .tn-icon {
        font-weight: normal;
    }

    .tn-promo-box {
        input {
            margin: 0;
            border: 1px solid $secondary;
            flex: 1 1 auto;
            padding: $space;
            border-right: 0;
        }
    }

    #tn-apply-promo {
        display: flex;
    }

    .tn-subnav-promo-button {
        flex: 0 0 auto;
        text-transform: capitalize;
        &::before {
            content: "Apply";
        }
    }
}

.tn-subnav-component .tn-cart-link, .tn-subnav-component .tn-login-link, .tn-subnav-component .tn-promo-box {
    white-space: normal;
}

.tn-promo-box {
    margin-right: 0;
}

.tn-subnav-component .tn-login-link {
    min-width: 0;
    overflow: hidden;

    @include media-min(medium) {
        flex: 1 1 auto;
    }
}

.tn-subnav-component .tn-login-link.tn-logged-in .tn-account-info-link {
    @include media(large) {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
    }
}

* + .tn-flex-mobile-cart-drawer__btn-add-to-cart {
    margin-top: 0;
}
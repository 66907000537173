/// <reference path="../../entries/main">

body {
    @extend %body;
}

.preheading {
    @extend %small;
}

.large {
    @extend %large;
}

.small {
    @extend %small;
}

a {
    @extend %hyperlink;

    &[href*="mailto"] {
        word-break: break-word;
    }
}

h3, .h3-style {
    & > a {
        border-width: 1.5px;
    }
}

h1, h2, .h1.style, .h2-style {
    & > a {
        border-width: 2px;
    }
}

.no-border {
    @extend %unlink;
}

b,
strong,
.bold {
    font-weight: $font-weight-bold;
}

em,
i,
.italic {
    font-style: italic;
}

sup,
sub {
    font-size: calc(50% + 0.4rem);
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.4em;
}

sub {
    top: 0.4em;
}

.align {
    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }
}

.header + * {
    @extend %text-top-margin;
}

.light {
    color: $charcoal-72;
}

.label {
    @extend %label;

    &-block {
        display: block;
    }
}

.uppercase {
    text-transform: uppercase;
}

.normal-weight {
    font-weight: $font-weight-normal;
}

.preheading {
    display: block;
    margin-bottom: $space;
}

.has-preheading {
    display: flex;
    flex-direction: column;

    & > * {
        max-width: 100%;
        min-height: 1px;
    }

    .preheading {
        order: -1;
        margin-top: 0;
    }
}

.error-msg {
    color: $brand-red-dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &[id] {
        scroll-margin-top: $sticky-nav-height + $gap-sm;
        scroll-margin-top: calc(var(--nav-margin-offset) + #{$gap});
    }
}

// Theme Specific

.theme-charcoal {
    a {
        @include link-helper($white, $brand-gold, $brand-gold-dark-active);
    }
}

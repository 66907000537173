﻿// Basic palette
$black: #000000;
$white: #ffffff;
$white-72: rgba($white, .72);
$white-48: rgba($white, .48);
$white-24: rgba($white, .24);
$charcoal: #24211B;
$charcoal-72: rgba($charcoal, .72);
$charcoal-48: rgba($charcoal, .48);
$charcoal-16: rgba($charcoal, .16);
$charcoal-4: rgba($charcoal, .04);


//Grays
$brand-gray: #24363F;
$brand-dark-gray: $brand-gray;
$brand-cool-gray: #444B4A;
$brand-light-gray: #9EACAB;
$brand-lightest-gray: #DBDBDB;

//Primary Colors
$brand-gold: #8F5D00;
$brand-gold-active: #744B00;
$brand-gold-dark: #A78147;
$brand-gold-dark-active: #91610B;
$brand-red: #CA3B3C;
$brand-red-dark: #9D3435;

// Accent Colors
$accent-yellow: #FFC043;
$accent-gray: #E5E5E5;

// Project colors
$disabled: rgba(#444B4A, 0.32);
$body-color: $charcoal;
$background-overlay: $brand-gray;
$form-input-accent: $brand-red;
$ui-invalid: $brand-red;
$ui-valid: #005718;
$ui-warning: $brand-red;
$focus-color: #4AACFF;
$primary-calendar-light: $brand-gold;
$primary-calendar-medium: $brand-gold;
$primary-calendar-dark: $brand-gold;
$calendar-accent: #F6F6F6;

$secondary-calendar-medium: $brand-gold;
$secondary-calendar-dark: $brand-gold;

$shadow: 0.16rem 0.16rem 0.6rem rgba($brand-gold, 0.3);


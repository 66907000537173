﻿/// <reference path="../entries/main">

.media {
    padding: 3rem 0;

    &.layout-full-width {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;

        @include media(tablet) {
            align-content: flex-start;
            align-items: flex-start;
        }
    }

    &.layout-centered {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;

        @include media(tablet) {
            align-content: flex-start;
            align-items: flex-start;
        }

        .media-container {
            // specified in Figma
            max-width: pxToRem(700px);
            margin: 0 auto;

            @include media(small) {
                max-width: none;
            }
        }
    }

    &-caption {
        @extend %media-figcaption;
    }
}

// Dark Theme
.theme-charcoal.media {
    color: $white;

    .media-caption {
        @extend %media-figcaption-dark;
    }
}

﻿/// <reference path="../entries/main">

.pagination {

    &-container {
        display: flex;
        list-style-type: none;
        margin-top: $gap * 2;
        justify-content: center;

        li {
            display: flex;
        }
    }

    &-button {
        align-items: center;
        background: $brand-gold-dark;
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 0;
        top: 50%;
        height: 2em;
        width: 2em;
        z-index: 1;

        &:hover {
            background: $brand-gold-dark-active;
        }

        svg {
            fill: $white;
        }

        &.disabled, [disabled] {
            background: $brand-gold;
            opacity: .5;
        }

        &-left {
            margin-right: $gap-sm;
        }

        &-right {
            margin-left: $gap-sm;
        }
    }

    &-item {
        padding: 0px $space;
        text-align: center;
        display: flex;
        align-items: center;
        @extend %hyperlink;


        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }

        &.selected {
            color: $brand-gold;
            text-decoration: none;
        }
    }
}


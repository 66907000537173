﻿/// <reference path="../entries/main">

$donor-page-breakpoint: tablet;

.donor {

    &-header {
        * + * {
            padding-top: $gap-sm;
        }

        @include media-min($donor-page-breakpoint) {
            display: flex;
            justify-content: space-between;
            align-items: center;

            * + * {
                padding-top: 0;
                padding-left: $gap-sm;
            }
        }

        &-cta {
            @include media-min(medium) {
                flex-grow: 1;
                text-align: right;
            }
        }
    }

    &-container {
        background: $accent-gray;
        @extend %standard-padding-v;


        &-header {
            margin-bottom: $gap-md * 2;
            //Override heading styles
            span {
                display: inline;
            }

            & > *:first-child {
                padding-right: $gap;
            }
        }
    }

    &-year {
        width: 40%;
        background: $charcoal;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include responsive-context($medium-vertical-gap-map) {
            padding: $responsive-variable;
        }

        @include media($donor-page-breakpoint) {
            width: 100%;
        }

        & > * {
            color: $white;
        }

        * + * {
            margin-top: $space;
        }


        &-item {
            display: flex;

            @include media($donor-page-breakpoint) {
                flex-direction: column;
            }

            & + & {
                margin-top: $gap-md * 2;
            }
        }
    }

    &-donations-list {
        flex-grow: 1;
        background: $white;

        @include responsive-context($medium-vertical-gap-map) {
            padding: $responsive-variable;
        }

        &-item {
            padding: $gap 0;
            border-top: $common-border;
            display: flex;
            justify-content: space-between;

            &:last-child {
                border-bottom: $common-border;
            }
        }
    }
}

﻿/// <reference path="../entries/main">

%visually-hidden {
    @include visually-hidden;
}

%hide {
    @include hide;
}

%text-top-margin {
    margin-top: 1em;
}

%medium-top-margin {
    margin-top: 0.5em;
}

%small-top-margin {
    margin-top: 0.325em;
}

%sixteen-by-nine {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;

    img {
        @extend %cover-img;
    }
}

%blur-background {
    filter: blur($blur-radius);

    &::after {
        @extend %full-cover;
        content: '';
        background-color: $brand-gold;
    }
}

%border-top {
    border-top: 1px solid $brand-gold;
}

%border-bottom {
    border-bottom: 1px solid $brand-gold;
}

%inner-padding {
    padding: 0 $gap;

    @include media($navigation-breakpoint) {
        padding: 0 $gap-md;
    }
}

%media-figcaption {
    @extend %text-top-margin;
    @extend %small;

    figcaption {
        color: $charcoal-72;
    }
}

%media-figcaption-dark {
    @extend %text-top-margin;
    @extend %small;
    color: $white;

    figcaption {
        color: $white;
    }
}

﻿/// <reference path="../../entries/main">
$pdp-breakpoint: "tablet";

// General
.pdp {
    padding-top: $gap;

    &-wrapper {
        display: flex;
        @extend %standard-padding-v;
        padding-top: 0;

        @include media($pdp-breakpoint) {
            flex-direction: column-reverse;
        }
    }
}

// Column Setup
.pdp-column {

    &-container {
        display: flex;

        @include media($pdp-breakpoint) {
            flex-direction: column;
        }
    }


    &-third {
        width: calc(100%/3);
        min-width: calc(100%/3);

        @include responsive-context($standard-padding-map) {
            padding-right: $responsive-variable;
        }

        @include media($pdp-breakpoint) {
            width: 100%;
            margin-bottom: $gap;
            padding-right: 0;
        }


        &-item + &-item {
            margin-top: $gap-md;
        }
    }

    &-twothird {
        flex-grow: 1;
    }
}

// Promo Code
.pdp-promo-code {

    &-light input {
        background: $white;
        color: $charcoal;
        border-color: $white;
    }



    &-apply {
        border: 1px solid $brand-gold;

        &:hover, &:active, &:focus {
            background: $brand-gold-dark-active;

            &::after {
                border-left-color: transparent;
                @include icon-btn-arrow($white);
            }
        }
    }

    &-container {
        display: flex;
        margin-top: $space;
    }

    &-applied-text {
        margin-bottom: $gap-sm;

        & > * {
            color: $ui-valid;
        }
    }
}

//Tickets
.pdp-tickets {
    background: $accent-gray;

    &-item {
        padding: $gap-md 0;
        border-top: 1px solid $charcoal-16;
        display: flex;
        align-items: center;
        gap: $gap-sm;

        @include media(tablet) {
            align-items: flex-start;
            flex-direction: column;
        }

        .tickets-time {
            text-align: center;

            @include media(tablet) {
                text-align: right;
                padding-right: 0;
            }
        }

        .tickets-date {
            width: calc(100%/2);
        }

        &-details {
            width: calc(100%/3);
            text-align: right;
        }


        &-info {
            display: flex;
            flex-grow: 1;

            @include media(tablet) {
                width: 100%;
                margin-bottom: $gap-sm;
            }

            &-details {
                display: flex;
                gap: $space-lg;
                flex: 1 1 auto;
                justify-content: center;

                @include media(tablet) {
                    justify-content: flex-end;
                }

                .svg-icon {
                    fill: $brand-gold;
                    margin-bottom: 0;
                    height: 1em;
                    width: 1em;
                }
            }
        }
    }

    &-cta {
        @include media(tablet) {
            text-align: left;
        }
    }

    &-cta, &-item-details {
        @include media(tablet) {
            width: 100%;
            max-width: none;
        }
    }


    &-reveal-cta {
        margin-top: $gap;
        @extend %btn-plus-minus;
        @include button-reveal($brand-gold, $white);
    }
}

﻿/// <reference path="../../entries/main">
.ace-cal {
    &-grid {
        &-day {
            &-date {
                padding: $gap-sm;
                display: block;
                color: $brand-gold;
            }

            &-has-events {
                transition: $transition;

                &:hover {
                    background: $charcoal;
                    color: $white;

                    .btn {
                        background: $secondary-calendar-dark;
                    }
                }

                &:focus-within { //must be separate so that IE doesn't drop styles entirely
                    background: $charcoal;
                    color: $white;

                    .btn {
                        background: $secondary-calendar-dark;
                    }
                }
            }
        }

        &-day-has-events:hover &-event-name a,
        &-day-has-events:hover &-event-time {
            color: $white;
        }
        //must be separate so that IE doesn't drop styles entirely
        &-day-has-events:focus-within &-event-name a,
        &-day-has-events:focus-within &-event-time {
            color: $white;
        }

        &-day-has-events:hover &-event-venue,
        &-day-has-events:focus-within &-event-venue {
            color: $white-72;
        }


        &-event {
            padding: $gap-sm;
            padding-top: 0;

            & + & {
                padding-top: $gap-sm;
            }

            &-time {
                font-weight: $font-weight-bold;
            }

            &-content {
            }

            &-venue {
                color: $charcoal-72;
            }

            &-cta a {
                margin-top: $space;
                border: none;
                padding: $space;
                text-align: center;

                &:after {
                    content: none;
                }
            }
            &-name {
                line-height: 1.25;
            }
        }
    }
}

// BACKGROUND IMAGE

.ace-cal {
    &-grid {
        &-day {
            position: relative;

            &-date {
                position: relative;
                z-index: 2;
            }
        }

        &-event {
            &-bg {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                pointer-events: none;
                background-size: cover;
                object-position: var(--focal-point-h) var(--focal-point-v);
                opacity: 0;
                transition: $transition;
                z-index: 1;

                & ~ * {
                    position: relative;
                    z-index: 2;
                }
            }

            &:hover &-bg {
                opacity: .3;
            }

            &:focus-within &-bg {
                opacity: .3;
            }
        }
    }
}

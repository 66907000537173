﻿/// <reference path="../entries/main">

.quote {
    position: relative;
    background-color: $charcoal-4;

    &-background {

        &:after {
            content: '';
            @extend %full-cover;
            background: rgba($charcoal, 0.8);
        }

        img {
            @extend %full-cover;
            object-fit: cover;
            object-position: var(--focal-point-h) var(--focal-point-v);
        }
    }

    .icon-blockquote {
        fill: $brand-gold;
        height: pxToRem(40px);
        width: pxToRem(115px);
        margin: 0 auto;
        display: block;
        position: relative;


        @include media(tablet) {
            height: pxToRem(20px);
            width: pxToRem(70px);
        }
    }

    .quote-container {
        position: relative;
        margin-top: $gap-lg;
        display: flex;
        justify-content: center;

        @include media(tablet) {
            flex-direction: column;
            padding-left: $gap;
            padding-right: $gap;
        }
    }

    .blockquote {
        flex-grow: 1;

        &.count-1 {
            max-width: 100%;
        }

        &.count-2 {
            max-width: calc(100%/2);

            @include media(tablet) {
                max-width: none;
            }
        }

        &.count-3 {
            max-width: calc(100%/3);

            @include media(tablet) {
                max-width: none;
            }
        }


        & + .blockquote {
            border-left: 1px solid $charcoal-16;

            @include media(tablet) {
                margin-top: $gap;
                padding-top: $gap;
                border-top: 1px solid $charcoal-16;
                border-left: none;
            }
        }

        &-inner {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            text-align: center;
            padding: 0 3rem;
        }
    }

    &-title {
        font-weight: $font-weight-normal;
        font-family: $accent-font;
    }

    .divider {
        display: block;
        padding: 0 $gap;
        margin: $gap auto;
        width: 50%;
        max-width: pxToRem(150px);

        &::after {
            content: '';
            display: block;
            border-bottom: 1px solid $brand-gold;
        }
    }

    &-info {
        & + & {
            margin-top: $space;
        }
    }

    &-description > p, &-attribution {
        @extend %small;
        text-transform: uppercase;
        color: $charcoal-72;
    }

    &-attribution {
        color: $brand-gold;
        font-weight: $font-weight-bold;
    }
}

// Overrides for when an image BG is present
.quote-container.has-image {
    .quote-title, .quote-description > p {
        color: $white;
    }

    .blockquote + .blockquote {
        border-color: $white-24;

        @include media(tablet) {
        border-color: $white-24;
        }
    }
}

﻿/// <reference path="../../entries/main">

.#{$prefix}block,
.#{$prefix}grid,
.component {
    @extend %component;
    scroll-margin-top: var(--nav-clearance);

    // Override ALL margins above so that adjacent full-bleed blocks can be flush
    &-no-margin {
        margin: 0;
    }

    &-padded {
        @extend %standard-padding-v;
    }
    &-full-bleed {
        margin: 0;
    }
}

.#{$prefix}block,
.component {
    &-inner {
        @extend %standard-max-width;

        @include responsive-context($component-gutter-map) {
            padding-left: $responsive-variable;
            padding-right: $responsive-variable;
        }
    }

    &-narrow {
        width: 100%;
        max-width: $max-width-narrow;
        margin-left: auto;
        margin-right: auto;

        @include media(tablet) {
            max-width: none;
        }
    }
}



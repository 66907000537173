﻿/// <reference path="../entries/main">
@import '_gallery-vendor.scss';
$splide-arrows-width: 6.875rem;
$splide-arrows-width-reduced: 5.875rem;
$splide-arrows-offset: 3rem;

.mediagallery {
    @include media(tablet) {
        margin-bottom: $gap;
    }

    @include media(x-small) {
        margin-bottom: $gap-lg;
    }

    &-wrapper {
        position: relative;
        overflow: hidden;

        @include media(tablet) {
            @include responsive-context($block-margin-map) {
                padding-bottom: $responsive-variable * 2;
            }
        }
    }


    &-slide {
        position: relative;
        width: calc(85% - #{$gap});
        white-space: normal;

        @include media(tablet) {
            width: calc(100% - #{$gap-sm});
        }


        & > .splide__video__play {
            //Hidden because we added one in the markup.
            display: none !important;
        }
    }



    &-slide-inner {
        padding: 0;
        position: relative;
        overflow: hidden;
        height: 0;
        margin: 0 auto;
        padding-top: 9 / 16 * 100%;

        img, video, iframe, picture {
            @extend %full-cover;
            object-fit: cover;
            object-position: var(--focal-point-h) var(--focal-point-v);
        }

        &.item-portrait {

            img, video, iframe, picture {
                @extend %full-cover;
                object-fit: contain;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                right: auto;
                width: auto;
                object-position: var(--focal-point-h) var(--focal-point-v);
            }
        }

    }

    &-intro {
        margin-bottom: $gap-md * 2;
        padding-right: $splide-arrows-width + $gap-sm;
        min-height: 90px;

        @include media(small) {
            padding-right: 0;
            margin: 0;
            margin-bottom: $gap;
        }

        & * + * {
            margin-top: $space;
        }
    }

    &-item-caption {
        margin-top: $gap;

        @include media(tablet) {
            margin-top: $space;
        }
    }
}

/*----SPLIDE OVERRIDES----*/
.mediagallery {
    .splide__track {
        overflow: visible;
    }

    .splide__arrows {
        display: flex;
        width: $splide-arrows-width;
        position: absolute;

        @include media-min(tablet) {
            top: 5rem;
            right: 0;
        }

        @include media(tablet) {
            width: 100%;
            justify-content: space-between;
            margin-bottom: $gap-sm;
            top: auto;
            bottom: -7rem;
        }
    }

    .splide__arrow {
        @extend %btn;
        @extend %btn-round;
        background-color: $brand-gold-dark;
        position: relative;
        left: auto;
        right: auto;
        opacity: 1;

        &:after {
            content: '';

            @include icon-arrow($white)
        }

        &:hover, &:focus, &:active {
            background-color: $brand-gold-dark-active;
        }

        svg {
            fill: $white;
        }

        &[disabled] {
            background-color: $charcoal-16;

            &:hover, &:focus {
                background-color: $charcoal-16;
            }
        }

        & + .splide__arrow {
            margin-left: $space;
        }
    }

    .splide__video__play {
        height: 3rem;
        width: 3rem;
        background-color: $white;

        &:hover, &:focus {
            background-color: $brand-gold-dark;

            svg {
                fill: #1b2327;
            }
        }
    }

    .splide.is-active .splide__list {
        width: 100%;
    }

    .splide__video {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;

        iframe, video {
            @extend %full-cover;
        }
    }
}


/*----Theme Specific----*/
.mediagallery-wrapper.theme {
    &-charcoal {
        .mediagallery-intro > *,
        .mediagallery-intro .rich-text,
        .mediagallery-item-caption {
            color: $white;
        }

        .mediagallery .splide__arrow[disabled] {
            background-color: $white-24;
        }
    }

    &-yellow {
        .mediagallery .splide__arrow {
            background-color: $charcoal;
        }

        .mediagallery .splide__arrow[disabled] {
            background-color: $charcoal-48;
        }
    }
}

﻿/// <reference path="../entries/main">

$_image-ratio: 0.65;
$_image-ratio-sm: 1;
$header-max-width: pxToRem(600px);

.content-page {

    &-header-inner {
        @extend %standard-max-width;
    }

    &-header.has-image {
        margin-bottom: 30%;

        @include media-min(x-large) {
            margin-bottom: $header-max-width / 1.5;
        }

        .content-page-description {
            padding-bottom: 0;
        }
    }

    &-header-breadcrumbs {

        .breadcrumbs {
        padding-top: $gap-sm;
            margin-bottom: 0;
        }
    }

    &-container {
        max-width: $header-max-width;
        text-align: center;
        margin: 0 auto;
        padding-top: $component-top-spacing;

        &.full-width {
            max-width: none;
        }

        @include media(small) {
        padding-top: $component-top-spacing/2;

        }
    }

    &-description {
        color: $charcoal-72;
        margin: $gap 0 0;
        padding-bottom: $gap-lg;

        @include media(medium) {
            margin-top: $gap-md;
            /*margin-bottom: $gap-md * 2;*/
        }
    }

    &-image-container {
        height: 0;
        min-height: 0;
        padding-top: $component-bottom-spacing / 2;
        padding-bottom: $_image-ratio * 100%;
        position: relative;
        right: 0;
        margin-top: -$_image-ratio / 2 * 100%;
        transform: translateY(50%);

        @include media(large) {
            margin-left: $gap;
            margin-right: $gap;
        }
    }
}

//theme specific
// Themes
.content-page-header {

    &.theme-charcoal {

        .content-page-title,
        .content-page-description {
            color: $white;
        }
    }

    &.theme-yellow {
        .content-page-title,
        .content-page-description {
            color: $charcoal;
        }
    }
}

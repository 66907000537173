/// <reference path="../entries/main">

%btn {
    @extend %btn-type;
    display: inline-block;
    padding: $button-form-padding;
    padding: var(--button-form-padding);
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    background: transparent;
    border: none;

    &[disabled] {
        cursor: not-allowed;
    }

    &.btn-large {
        padding: $button-form-padding-large;
    }

    .svg-icon {
        margin-left: $space;
    }

    .svg-icon-no {
        margin-left: $space;
    }

    .svg-on-left {
        margin-left: 0;
    }
}

%btn-arrow-base {
    @extend %btn;
    position: relative;
    background-color: transparent;
    padding-right: $btn-arrow-size + $space-lg;
    font-weight: $font-weight-bold;


    &::after {
        @extend %btn-arrow-psuedo;
    }

    &:active {
        color: $white;
    }

    &:hover, &:active, &:focus {
        color: $white;

        &:after {
            @include icon-btn-arrow($white);
        }
    }

    &[disabled],
    &[aria-disabled="true"] {
        color: $white;
        border-color: transparent;
        background: $disabled;

        &::after {
            @include icon-btn-arrow($white);
            border-color: $white;
        }

        &:hover {
            border-color: transparent;
            background: $disabled;
        }
    }
}

%btn-arrow-psuedo {
    content: '';
    height: 100%;
    width: $btn-arrow-size;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background-size: 18px;
    border-left: 1px solid $white-24;
}


%btn-primary,
%btn-primary-alt,
%btn-secondary,
%btn-secondary-alt,
%btn-link {
    @extend %btn-arrow-base;
}

%btn-primary {
    @include button-helper($brand-gold, $brand-gold-active, $white);
}

%btn-primary-alt {
    @include button-helper($brand-gold, $brand-gold-active, $brand-gold);
    background: transparent;

    &:after {
        border-color: $brand-gold;
    }

    &:focus, &:focus-within {
        color: $brand-gold-active;

        &:after {
            @include icon-btn-arrow($brand-gold-active);
            border-color: $brand-gold-active;
        }
    }

    &:active {
        color: $white;

        &:after {
            border-color: $white;
        }
    }

    &:hover {
        color: $white;

        &:after {
            @include icon-btn-arrow($white);
            border-color: $white-24;
        }
    }

    &:active:focus {
        &:after {
            @include icon-btn-arrow($white);
            border-color: $white-24;
        }
    }


    &[disabled],
    &[aria-disabled="true"] {
        color: $disabled;
        border-color: $disabled;
        background: transparent;

        &::after {
            @include icon-btn-arrow($disabled);
            border-color: $disabled;
        }

        &:hover {
            border-color: $disabled;
            background: transparent;
        }
    }
}

%btn-secondary {
    @include button-helper($brand-red, $brand-red-dark, $white);

    &:focus {
        color: $white;
    }
}

%btn-secondary-alt {
    @include button-helper($brand-red, $brand-red-dark, $brand-red);
    background: transparent;

    &:after {
        border-color: $brand-red;
    }

    &:focus, &:focus-within {
        color: $brand-red;

        &:after {
            @include icon-btn-arrow($brand-red);
            border-color: $brand-red;
        }
    }

    &:active {
        color: $white;

        &:after {
            border-color: $white;
        }
    }

    &:hover {
        color: $white;

        &:after {
            @include icon-btn-arrow($white);
            border-color: $white-24;
        }
    }

    &:active:focus {
        &:after {
            @include icon-btn-arrow($white);
            border-color: $white-24;
        }
    }

    &[disabled],
    &[aria-disabled="true"] {
        color: $disabled;
        border-color: $disabled;
        background: transparent;

        &::after {
            @include icon-btn-arrow($disabled);
            border-color: $disabled;
        }

        &:hover {
            border-color: $disabled;
            background: transparent;
        }
    }
}

%btn-link-primary {
    @extend %btn-link;
    @include button-helper($brand-gold, $brand-gold-active, $brand-gold);
    color: $brand-gold;
    border: none;
    background: none;
    padding-right: $gap + $space;
    padding-left: 0;

    &:hover, &:focus, &:active {
        color: $brand-gold-active;
        background: none;

        &::after {
            @include icon-btn-arrow($brand-gold-active);
        }
    }
}

%btn-link-secondary {
    @extend %btn-link;
    @include button-helper($brand-red, $brand-red-dark, $brand-red);
    color: $brand-red;
    border: none;
    background: none;
    padding-right: $gap + $space;
    padding-left: 0;

    &:hover, &:focus, &:active {
        color: $brand-red-dark;
        background: none;

        &::after {
            @include icon-btn-arrow($brand-red-dark);
        }
    }
}

%btn-link-white {
    @extend %btn-link;
    @include button-helper($white, $charcoal, $white);
    border: none;
    background: none;
    padding-right: $gap + $space;
    padding-left: 0;

    &:hover, &:focus, &:active {
        color: $charcoal;
        background: none;
        transition: none;

        &::after {
            @include icon-btn-arrow($charcoal);
            border: none;
        }
    }

    &::after {
        border: none;
    }
}




%btn-round {
    border-radius: 9999em;
    width: $square-button-size;
    height: $square-button-size;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;

    .svg-icon {
        margin: 0;
    }
}

%un-button-un-style {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline;
    text-align: left;

    &:hover,
    &:focus {
        background-color: transparent;
    }

    &[disabled],
    &[aria-disabled="true"] {
        background-color: transparent;
        color: $brand-gold;
        cursor: not-allowed;
    }
}

// Splide Video Pattern
%play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;


    &:after {
        content: "";
        display: inline-block;
        border-color: transparent transparent transparent #000;
        border-style: solid;
        border-width: 7px 0 7px 11px;
        margin-left: 4px;
    }
}

%un-button {
    @extend %body;
    @extend %un-button-un-style;
}


%btn-right-aligned-icon {
    display: block;
    position: relative;
    padding-right: $svg-icon-sm;

    &:hover,
    &:focus {
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }

    &::after {
        opacity: 0;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: $svg-icon-sm;
        height: $svg-icon-sm;
        transition: $transition;
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;
    }
}

%btn-plus-minus {
    @include button-reveal();

    &[aria-expanded="true"] {
        &:after {
            background-size: 0.85rem;
        }
    }

    &[aria-expanded="false"] {
        &:after {
            background-size: $gap-sm;
        }
    }
}


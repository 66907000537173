﻿/// <reference path="../entries/main">

// This block shares markup and styles with "Bio"
// The styles below are simply overrides to _bio.scss


.contact {

    &-item {
        flex-direction: row;
        justify-content: flex-start;
        flex-grow: 0;
        padding: 0 $gap-md;

        @include media(large) {
            width: calc(100%/2);
        }

        @include media(tablet) {
            width: calc(100%/1);
        }


        &-image-container {
            width: 25%;
            min-width: 160px;
        }

        &-image {
            flex: 0 0 auto;
            width: 100%;
            max-width: $bio-image-max-width;

            @include media(small) {
                max-width: $bio-image-max-width-mobile;
            }
        }

        &-content-container {
            flex-grow: 1;
        }

        &-content {
            flex: 1 1 auto;
            text-align: center;
            margin-top: $gap-sm;
            max-width: none;
            padding: $space;
        }


        &-img {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
            border-radius: 999rem; //arbitrarily big number to guarantee circle

            &-content {
                flex: 1 1 auto;
                align-self: flex-start;
                text-align: left;
                margin-left: $gap-md * 2;

                @include media(large) {
                    margin-left: $gap-md;
                }
            }
        }
        &-pronouns {
            margin: 0;
        }
    }
}
